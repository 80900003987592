import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { ServicesContainer, ServicesContentContainer, ServicesHeader } from '../Styles/componentStyles/HeaderSection'

const HeaderSection = ({ title }) => {
    return (
        <Box sx={ServicesContainer}>
            <Box sx={ServicesContentContainer}>
                <Typography sx={ServicesHeader}>
                    {title}
                </Typography>
            </Box>
        </Box>
    )
}

HeaderSection.propTypes = {
    title: PropTypes.string.isRequired
}

export default HeaderSection