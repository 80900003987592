import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { useDispatch } from "react-redux";
import { encryptTransform } from "redux-persist-transform-encrypt";
import { exportApi } from "../Services/ExportApi";
import { importApi } from "../Services/ImportApi";
import userReducer from "./userSlice";

import { userApi } from "../Services/UserApi";
import { exportPaymentApi } from "../Services/ExportPaymentApi";
import { importPaymentApi } from "../Services/ImportPaymentApi";
import { accountApi } from "../Services/AccountApi";

const rootReducers = combineReducers({
  user: userReducer,
  [exportApi.reducerPath]: exportApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [importApi.reducerPath]: importApi.reducer,
  [exportPaymentApi.reducerPath]: exportPaymentApi.reducer,
  [importPaymentApi.reducerPath]: importPaymentApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  whiteList: [],
  transforms: [
    encryptTransform({
      secretKey: "altel-data-encryption-key",
      onError: function () {
        console.error("unable to encrypt redux store :(");
      },
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      exportApi.middleware,
      userApi.middleware,
      importApi.middleware,
      exportPaymentApi.middleware,
      importPaymentApi.middleware,
      accountApi.middleware
    ),
  devTools: true,
});

export const AppDispatch = store.dispatch;
export const RootState = store.getState;
export const useAppDispatch = () => useDispatch(AppDispatch);
export const persistor = persistStore(store);
