import { Box } from "@mui/material";
import HeaderSection from "../../Components/HeaderSection";
import ServicesSection from "../../Components/ServicesSection";

const Services = () => {
    return (
        <Box>
            <HeaderSection title="IMPORT SERVICES" />
            <ServicesSection sectionType="import" />
        </Box>
    )
}

export default Services