import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
import { getAccessToken, logOut, updateToken } from "./authService";
import { updateUserDetails, updateUserTokens } from "../Store/userSlice";

// eslint-disable-next-line no-undef
const BASE_URL = process.env.REACT_APP_BASE_URL;

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, api) => {
    headers.set("Authorization", `Bearer ${getAccessToken()?.access}`);
    return headers;
  },
});

const AuthenticatedBaseQuery = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (
    result.error &&
    (result.error.status === 403 ||
      (result.error.status === 401 &&
        result?.error?.data?.code === "user_not_found") ||
      result?.error?.data?.code === "user_inactive")
  ) {
    api.dispatch(updateUserDetails(null));
    logOut();
  }
  if (
    result.error &&
    result.error.status === 401 &&
    result?.error?.data?.code !== "user_not_found"
  ) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            url: "/auth/token/refresh/",
            method: "POST",
            body: { refresh: getAccessToken()?.refresh },
          },
          api,
          extraOptions
        );
        if (refreshResult?.data) {
          api.dispatch(updateUserTokens(refreshResult.data.access));
          updateToken(refreshResult.data.access);
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(updateUserDetails(null));
          logOut();
        }
      } catch (err) {
        api.dispatch(updateUserDetails(null));
        logOut();
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export default AuthenticatedBaseQuery;
