import { createApi } from "@reduxjs/toolkit/query/react";
import AuthenticatedBaseQuery from "./AuthenticatedQuery";

export const exportApi = createApi({
  reducerPath: "exportApi",
  baseQuery: AuthenticatedBaseQuery,
  endpoints: (builder) => ({
    exportFormOptions: builder.mutation({
      query: () => ({
        url: `/export/form-options/`,
        method: "GET",
      }),
    }),
    getExportShippingLinesData: builder.mutation({
      query: (params) => ({
        url: `/export/shipping-lines/by_yard/`,
        method: "GET",
        params,
      }),
    }),
    getExportContainerSizeTypeData: builder.mutation({
      query: (params) => ({
        url: `/export/container_size_types/by_yard_shipping_line/`,
        method: "GET",
        params,
      }),
    }),
    scheduleDateOptions: builder.mutation({
      query: (id) => ({
        url: `/export/schedule-dates/?yard_id=${id}`,
        method: "GET",
      }),
    }),
    timeSlotOptions: builder.query({
      query: (id) => ({
        url: `/export/time-slots/?schedule_date=${id}`,
        method: "GET",
      }),
    }),
    containerNumberList: builder.mutation({
      query: ({ container_size_type_id, yard_id, pickup_id }) => ({
        url: `/export/retrieve-container-numbers/`,
        method: "GET",
        params: {
          container_size_type_id: container_size_type_id,
          yard_id: yard_id,
          pickup_id: pickup_id,
        },
      }),
    }),
    createPickup: builder.mutation({
      query: (body) => ({
        url: `/export/pickup-create/`,
        method: "POST",
        body,
      }),
    }),
    updatePickup: builder.mutation({
      query: (body) => ({
        url: `/export/edit-pickup/`,
        method: "PATCH",
        body,
      }),
    }),
    deletePickup: builder.mutation({
      query: (id) => ({
        url: `/export/delete-pickup/?pickup_id=${id}`,
        method: "DELETE",
      }),
    }),
    getPickups: builder.mutation({
      query: (sortType) => ({
        url: `/export/get-pickups/`,
        method: "GET",
        params: {
          sort: sortType,
        },
      }),
    }),
    fetchThirthPartyTransporter: builder.mutation({
      query: (params) => ({
        url: `/import/fetch-third-party-transporter/`,
        method: "GET",
        params,
      }),
    }),
  }),
});

export const {
  useExportFormOptionsMutation,
  useGetExportShippingLinesDataMutation,
  useGetExportContainerSizeTypeDataMutation,
  useScheduleDateOptionsMutation,
  useLazyTimeSlotOptionsQuery,
  useContainerNumberListMutation,
  useCreatePickupMutation,
  useGetPickupsMutation,
  useDeletePickupMutation,
  useUpdatePickupMutation,
  useFetchThirthPartyTransporterMutation,
} = exportApi;
