import { Box, Grid, Typography } from "@mui/material";
import { Icon } from '@iconify-icon/react';
import * as styles from '../Styles/HomePage/footer';
import { useLazyGetHomePageContentQuery } from "../Services/UserApi";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { updateExportService, updateImportService } from "../Store/userSlice";
import CopyRightIcon from "../Assets/icons/copyright.svg"


const FooterImage = {
  "img": {
    display: "flex",
    margin: "auto",
    width: {
      xs: "100%",
      sm: "100%",
      md: "100%",
      lg: "100%",
      xl: "100%",
    },
    height: {
      xs: "50px",
      sm: "50px",
      md: "70px",
      lg: "70px",
      xl: "70px",
    },
  },
};
const Footer = () => {
  const user = useSelector(state => state?.user);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [getHomePageContentApi, { data }] = useLazyGetHomePageContentQuery()
  useEffect(() => {
    if (user?.tokens?.refresh) {
      getHomePageContentApi()
    }
  }, [user?.tokens, getHomePageContentApi])


  useEffect(() => {
    if (data) {
      dispatch(updateImportService(data?.data?.import_service))
      dispatch(updateExportService(data?.data?.export_service))
    }
  }, [data, dispatch])

  const handleCall = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <footer style={{
      backgroundColor: '#EBEBEB',
      padding: '2rem 0 0 0'
    }}>
      <Box sx={styles.footerContainer}>
        <Grid container >
          <Grid item md={3} xs={12} padding={"0 40px"}>
            <Box sx={FooterImage}>
              {/* <img src={LogoIcon} alt="Container icon" style={{margin:"0 auto 1rem auto"}} /> */}
              <Typography sx={{ ...styles.footerCategoriesTitle }}>About Us</Typography>
              <Typography sx={{
                textAlign: {
                  xs: "center",
                  sm: "center",
                  md: "justify",
                  lg: "justify",
                  xl: "justify",
                }, fontSize: {
                  xs: "14px",
                  sm: "16px",
                  md: "16px",
                  lg: "18px",
                  xl: "20px",
                }
              }}>
                {data?.data?.footer_company_info?.company_description}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={2} xs={12} sx={styles.footerLinksWrapper}>
            <Box>
              <Typography sx={styles.footerCategoriesTitle}> IMPORT </Typography>
              <Typography sx={{...styles.footerSubCategory, cursor:"pointer"}}
                onClick={() => {
                  navigate('/import/drop-off');
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              > Drop-off </Typography>
              {user?.services?.import &&
                <Typography sx={{ ...styles.footerSubCategory, cursor: "pointer" }}
                  onClick={() => {
                    navigate('/import/services');
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                > Services </Typography>}
            </Box>
          </Grid>
          <Grid item md={2} xs={12} sx={styles.footerLinksWrapper}>
            <Box>
              <Typography sx={styles.footerCategoriesTitle}> EXPORT </Typography>
              <Typography sx={{ ...styles.footerSubCategory, cursor: "pointer" }}
                onClick={() => {
                  navigate('/export/pick-up');
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              > Pick-Up </Typography>
              {user?.services?.export &&
                <Typography sx={{ ...styles.footerSubCategory, cursor: "pointer" }}
                  onClick={() => {
                    navigate('/export/services');
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                > Services </Typography>}
            </Box>
          </Grid>
          <Grid item md={2} xs={12} sx={styles.footerLinksWrapper}>
            <Box>
              <Typography sx={styles.footerCategoriesTitle}> BILLING </Typography>
              <Typography sx={{ ...styles.footerSubCategory, cursor: "pointer" }}
                onClick={() => {
                  navigate('/billing/invoices');
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              > Billing / Invoices  </Typography>
              <Typography sx={{ ...styles.footerSubCategory, cursor: "pointer" }}
                onClick={() => {
                  navigate('/billing/transaction-detail');
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              > Transaction History </Typography>
            </Box>
          </Grid>
          <Grid item md={3} xs={12} padding={"0 40px"}>
            <Box>
              <Typography sx={{
                ...styles.footerCategoriesTitle,
                textAlign: {
                  xs: "center",
                  sm: "center",
                  md: "left",
                  lg: "left",
                  xl: "left",
                }
              }}> ADDRESS </Typography>
              <Typography sx={{
                textAlign: {
                  xs: "center",
                  sm: "center",
                  md: "justify",
                  lg: "justify",
                  xl: "justify",
                }, fontSize: {
                  xs: "14px",
                  sm: "16px",
                  md: "16px",
                  lg: "18px",
                  xl: "20px",
                }
              }}>
                {data?.data?.footer_company_info?.address}
              </Typography>

            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={styles.socialLinksContainer}>
        <Box sx={styles.socialLinks} onClick={() => window.open(data?.data?.social_links?.website, "_blank")}>
          <Icon icon="mdi:web" width="28" height="28" style={styles.socialIcons} />
        </Box>
        <Box sx={styles.socialLinks} onClick={() => window.open(data?.data?.social_links?.linkedin, "_blank")}>
          <Icon icon="ri:linkedin-fill" width="26" height="26" style={styles.socialIcons} />
        </Box>
        <Box sx={styles.socialLinks} onClick={() => window.open(data?.data?.social_links?.whatsapp, "_blank")}>
          <Icon icon="ic:baseline-whatsapp" width="28" height="28" style={styles.socialIcons} />
        </Box>
        <Box sx={styles.socialLinks} onClick={() => handleCall(data?.data?.footer_company_info?.phone_number)}>
          <Icon icon="ic:baseline-phone" width="28" height="28" style={styles.socialIcons} />
        </Box>
      </Box>
      <Box sx={styles.footerCopyRightConatiner}>
        <Typography align="center" color={"#fff"} padding={".5rem 0"}>
          <img src={CopyRightIcon} alt="copyright symbol"/>{' '}
          Copyright All rights reserved.
        </Typography>
      </Box>
    </footer>
  )
}

export default Footer