const formContainer = {
  width: {
    xs: "90%",
    sm: "90%",
    md: "90%",
    lg: "80%",
    xl: "70%",
  },

  margin: "1rem auto 0 auto",
};

const formTitle = {
  fontWeight: 600,
  fontSize: {
    xs: "14px",
    sm: "20px",
    md: "20px",
    lg: "24px",
    xl: "30px",
  },
  // marginTop:"2rem",
  // lineHeight: "41px",
  fontFamily: "Poppins",
  textAlign: "center",
};

const formFieldHeadings = {
  fontWeight: "500",
  fontFamily: "Roboto",
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "14px",
    lg: "14px",
    xl: "16px",
  },
  lineHeight: "16px",
  mb: "0.5rem",
};

const addDetailsButton = {
  margin: {
    xs: "0.5rem 0",
    sm: "1rem 0",
    md: "1.2rem 0",
    lg: "1.4rem 0",
    xl: "2rem 0",
  },
  textTransform: "none",
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "14px",
    lg: "14px",
    xl: "16px",
  },
  fontFamily: "poppins",
  fontWeight: "400",
  background: "#933118",

  "&.MuiButton-root:hover": {
    backgroundColor: "#6e1e0a !important",
  },
};

const submitDetailsButton = {
  textTransform: "none",
  margin: {
    xs: "0.5rem 0",
    sm: "1rem 0",
    md: "1.2rem 0",
    lg: "1.4rem 0",
    xl: "2rem 0",
  },
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "14px",
    lg: "14px",
    xl: "16px",
  },
  fontFamily: "poppins",
  fontWeight: "400",
  background: "#933118",

  "&.MuiButton-root:hover": {
    backgroundColor: "#6e1e0a !important",
  },
};

const formFieldError = {
  color: "#d32f2f",
  fontFamily: "poppins",
  fontWeight: "400",
  fontSize: "0.75rem",
  lineHeight: "1.66",
  letterSpacing: "0.03333em",
  textAlign: "left",
  marginTop: "3px",
  marginRight: "14px",
  marginBottom: "0",
  marginLeft: "14px",
};

export {
  formTitle,
  formContainer,
  formFieldHeadings,
  addDetailsButton,
  submitDetailsButton,
  formFieldError,
};
