import { createApi } from "@reduxjs/toolkit/query/react";
import AuthenticatedBaseQuery from "./AuthenticatedQuery";

export const userApi = createApi({
  reducerPath: "dashboardUserApi",
  baseQuery: AuthenticatedBaseQuery,
  endpoints: (builder) => ({
    getUserDetails: builder.mutation({
      query: () => ({
        url: `/auth/users/retrieve/`,
        method: "GET",
      }),
    }),
    getServicesContent: builder.query({
      query: (params) => ({
        url: `/home/service-content/`,
        method: "GET",
        params: {
          ...params,
        },
      }),
    }),
    getHomePageContent: builder.query({
      query: () => ({
        url: `/home/home-content/`,
        method: "GET",
      }),
    }),
    getHomePage: builder.mutation({
      query: () => ({
        url: `/home/home-content/`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetUserDetailsMutation,
  useLazyGetServicesContentQuery,
  useLazyGetHomePageContentQuery,
  useGetHomePageMutation,
} = userApi;
