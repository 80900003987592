import React from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const BasicDateField = ({ id, name, value, label, disabled, handleChange, sheduledDates, sheduledDatesLoading, fieldTouched, fieldError }) => {
    return (
        <FormControl fullWidth error={fieldTouched && fieldError}>
            <LocalizationProvider sx={{ width: '100%' }} dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    error={fieldTouched && fieldError}
                    id={id}
                    name={name}
                    format='DD-MM-YYYY'
                    label={label}
                    disabled={disabled}
                    onChange={(date) => handleChange(date)}
                    value={value ? dayjs(value) : null}
                    slotProps={{
                        textField: {
                            placeholder: "Select Date",
                            InputProps: {
                                disabled: true, // Prevent manual input
                            },
                        },
                    }}
                    sx={{
                        borderRadius: '8px',
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: () => `${(fieldTouched && fieldError) ? "1px solid red" : "1px solid black"}`,
                            borderRadius: '8px'
                        },
                        width: '100%',
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                        },
                    }}
                    shouldDisableDate={(date) => dayjs(date).isBefore(dayjs(), 'day')}
                />
            </LocalizationProvider>
            <FormHelperText>{fieldTouched && fieldError && `${label} is Required`}</FormHelperText>
        </FormControl>
    )
}

BasicDateField.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    sheduledDates: PropTypes.array,
    sheduledDatesLoading: PropTypes.bool,
    fieldTouched: PropTypes.bool,
    fieldError: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string
}

export default BasicDateField