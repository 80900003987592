import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, {  useState } from 'react';
import PropTypes from 'prop-types';
import { BookButton, HeadingStyle, TableCellStyle, TableHeader } from '../../Styles/Form/Field';
import CheckIcon from '@mui/icons-material/Check';
import { formContainer } from '../../Styles/Import/formSection';


const ExportContainerTable = ({ containerData, handleClick, formData, formOptions, shippingLines, containerSizeTypes }) => {
  const [selectedFields, setSelectedFields] = useState({});

  const handleSelect = (selected, container_number) => {
    setSelectedFields((prev) => ({
      ...prev,
      [container_number]: selected,
    }));
  };

  const handleSubmit = () => {
    const selectedContainerNumbers = containerData.map(ele => selectedFields[ele?.container_number] && ele.id).filter(Boolean)
    handleClick(selectedContainerNumbers)
  }


  return (
    <Box sx={{ background: ' #F5F5F5 0% 0% no-repeat padding-box' }}>
      <Box sx={{ ...formContainer, margin: "auto", paddingBottom: "3rem" }}>
        <Typography sx={{ ...HeadingStyle, paddingTop: "2rem" }}>
          Allotments
        </Typography>
        <TableContainer sx={{ borderRadius: '8px' }}>
          <Table
            sx={{ minWidth: 650, borderRadius: '8px' }}
            aria-label="simple table"
          >
            <TableHead
              sx={{ background: '#ECECEC', borderRadius: '8px 8px 0 0' }}
            >
              <TableRow sx={{ borderRadius: '8px' }}>
                <TableCell sx={TableHeader} align="center">
                  Sr. No
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Container Number
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Container Size Type
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Shipping Line
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Condition
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Survey Link
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Select
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {containerData?.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={{ fontSize: '16px' }} align="center">
                    {index + 1}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    {row?.container_number}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    {containerSizeTypes?.find(ele => ele.id === formData?.container_size_type)?.container_size_type}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    {shippingLines?.find(ele => ele.id === formData?.shipping_line)?.name}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    survey
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    {row?.survey_link ? row?.survey_link :"-"}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    <CheckIcon sx={{
                      cursor: "pointer",
                      borderRadius: "50%",
                      fill: selectedFields?.[row?.container_number] ? "#fff" : "#000000",
                      background: selectedFields?.[row?.container_number] ? "#5FB435" : "#DEDEDE",
                      width: "23px",
                      height: "23px"
                    }}
                      onClick={() => handleSelect(!selectedFields?.[row?.container_number] || false, row?.container_number)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '12px',
            borderRadius: '8px',
            m: '1rem 0rem',
          }}
        >
          <IconButton
            sx={{
              ...PaginationButton
            }}
            disabled={pages?.prev === undefined}
            onClick={handlePrev}>
            <KeyboardArrowLeftIcon sx={{fill:pages?.prev === undefined?"#000000":"#FFFFFF"}} />
          </IconButton>
          <Paper sx={{ padding: '0px 12px', borderRadius: '8px',...PaginationPageNumber }}>
            <Typography>{pages?.current}</Typography>
          </Paper>

          <IconButton
            variant="contained"
            sx={{
              ...PaginationButton
            }}
            onClick={handleNext}
            disabled={pages?.next === undefined}
          >
            <KeyboardArrowRightIcon sx={{fill:pages?.next === undefined?"#000000":"#FFFFFF"}} />
          </IconButton>
        </Box> */}
        <Button sx={{ ...BookButton }} onClick={handleSubmit} variant="contained" type="submit">
          Continue
        </Button>
      </Box>
    </Box>
  );
};
ExportContainerTable.propTypes = {
  containerData: PropTypes.arrayOf(PropTypes.any),
  formData: PropTypes.shape(PropTypes.any),
  handleClick: PropTypes.func,
  formOptions: PropTypes.shape(PropTypes.any),
  shippingLines: PropTypes.any,
  containerSizeTypes: PropTypes.any
};

export default ExportContainerTable;