import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { Backdrop, Box, Button, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Skeleton, TextField } from '@mui/material';
import { exportFormSchema } from '../../Utils/ValidationSchemas';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useContainerNumberListMutation, useExportFormOptionsMutation, useGetExportContainerSizeTypeDataMutation, useGetExportShippingLinesDataMutation, useUpdatePickupMutation } from '../../Services/ExportApi';
import ExportContainerModalTable from './ExportContainerModalTable';
import CustomModalUploadField from '../../Components/CustomModalUploadField';
import CustomModalDatePicker from '../../Components/CustomModalDatePicker';
import { BookButton } from '../../Styles/Form/Field';

const menuProps = {
    PaperProps: {
        style: {
            maxHeight: 200, // Adjust this value to change the height of the options list
        },
    },
};
export default function ExportUpdatePickupModal({ open, handleClose, initialFieldOptions, disableYard }) {
    const { enqueueSnackbar } = useSnackbar();
    const [formOtionsApi, { isLoading: formOptionsLoading }] = useExportFormOptionsMutation();
    const [fetchShippingLinesData, { isLoading: fetchingShippingLines, data: shippingLinesData }] = useGetExportShippingLinesDataMutation()
    const [fetchContainerSizeTypes, { isLoading: fetchingContainerSizeTypes, data: containerSizeTypes }] = useGetExportContainerSizeTypeDataMutation();
    const [containerListApi, { isLoading: ContainerListApiLoading, }] =
        useContainerNumberListMutation();
    const [updatePickupApi, { isLoading: updatePickupApiLoading }] = useUpdatePickupMutation();
    const [containerList, setContainerList] = useState();
    const [showContainerTable, setShowContainerTable] = useState(false);
    const [selectedContainers, setSelectedContainers] = useState(initialFieldOptions?.containers);
    const [formOtions, setFormOtions] = useState([]);
    const [updatedPickUpData, setUpdatedPickUpData] = useState({});

    const formik = useFormik({
        initialValues: {
            yard: initialFieldOptions?.yard?.id || '',
            shipping_line: initialFieldOptions?.shipping_line?.id || '',
            schedule_date: initialFieldOptions?.schedule_date?.date || '',
            bn_number: initialFieldOptions?.bn_number || '',
            validity: initialFieldOptions?.validity || '',
            container_size_type: initialFieldOptions?.container_size_type?.id || '',
            cargo_type: initialFieldOptions?.cargo_type?.id || '',
            bn_image: initialFieldOptions?.bn_image.split('/').pop().trim() || '',
        },
        validationSchema: exportFormSchema,
        onSubmit: (values, { resetForm }) => {
            handleExportUpdate(values, resetForm);
        },
    });


    useEffect(() => {
        if (formik?.values?.yard) {
            fetchShippingLinesData({ yard_id: formik?.values?.yard });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik?.values?.yard])


    useEffect(() => {
        if (formik?.values?.yard && formik?.values?.shipping_line) {
            fetchContainerSizeTypes({ yard_id: formik?.values?.yard, shipping_line_id: formik?.values?.shipping_line, pickup_id: initialFieldOptions?.id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik?.values?.yard, formik?.values?.shipping_line])

    useEffect(() => {
        const getFormOptions = async () => {
            const { data, error } = await formOtionsApi()
            if (data) {
                setFormOtions(data?.data)
            }
            if (error) {
                enqueueSnackbar(error?.data?.message || "Something went wrong !", {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                });
                console.log(error)
            }
        }
        getFormOptions()
    }, [initialFieldOptions, formOtionsApi, enqueueSnackbar])

    const handleExportUpdate = async (values, { resetForm }) => {
        const containerType = containerSizeTypes?.data?.container_size_types?.find(ele => ele.id === values?.container_size_type)?.id
        const { data, error } = await containerListApi({
            container_size_type_id: containerType, yard_id: formik?.values?.yard,
            pickup_id: initialFieldOptions?.id
        });
        if (data) {
            const selectedContainerslist = initialFieldOptions?.containers
            setSelectedContainers(selectedContainerslist)
            setUpdatedPickUpData(values)
            setShowContainerTable(true)
            setContainerList(data?.data);
        }
        if (error) {
            enqueueSnackbar(error?.data?.message || 'Something went wrong!', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });
            setContainerList([])
            console.log(error)
        }
    }

    const handleShippingLineChange = async (event) => {
        formik.setFieldValue('container_size_type', "")
        formik.handleChange(event);
    };

    const handleScheduleDateChange = async (value, id) => {
        formik.setFieldValue('validity', "")
        formik.setFieldValue('schedule_date', value);
    };
    const handleContainerSelection = async (selectedContainerNumbers) => {
        const formData = new FormData();
        Object.entries(updatedPickUpData).forEach(([key, value]) =>
            formData.append(key, value)
        );
        formData.delete('schedule_date')
        formData.append('schedule_date', updatedPickUpData?.schedule_date)
        selectedContainerNumbers.forEach(ele => {
            formData.append('container_ids', ele);
        })
        formData.append('status', 'scheduled')
        formData.append('id', initialFieldOptions?.id)
        formData.append('payment_status', 'pending')
        if (typeof (formik.values.bn_image) === 'string') {
            formData.delete('bn_image')
        }
        const { data, error } = await updatePickupApi(formData);
        if (data) {
            enqueueSnackbar('Pickup updated Succesfully !', {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });
            setContainerList();
            formik.resetForm();
            handleClose()
        }
        if (error) {
            enqueueSnackbar(error?.data?.message || 'Something went wrong!', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });
            console.log(error);
        }
    };
    const handleContainerSizeChange = async (event) => {
        const { name, value } = event.target
        formik.setFieldValue(name, value)
    }
    const getErrorMessage = (touched, error) => touched ? error : '';

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={"lg"}
                sx={{
                    '& .MuiDialog-paper': {
                        borderRadius: '16px'
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
                    {"Edit Export Pick Up Details"}
                </DialogTitle>
                <DialogContent sx={{ margin: '1rem' }}>
                    <form onSubmit={formik.handleSubmit}>
                        <Box sx={{ marginTop: '10px' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    {
                                        formOptionsLoading ?
                                            <Skeleton variant="rectangular" width={210} height={60} />
                                            :
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Yard</InputLabel>
                                                <Select
                                                    MenuProps={menuProps}
                                                    labelId="yard"
                                                    id="yard"
                                                    name='yard'
                                                    sx={{
                                                        '& .MuiSelect-select': {
                                                            fontSize: {
                                                                xs: '14px',
                                                                sm: '14px',
                                                                md: '14px',
                                                                lg: '16px',
                                                                xl: '18px',
                                                            },
                                                        },
                                                    }}
                                                    value={formik.values.yard}
                                                    label={'Yard'}
                                                    onChange={formik.handleChange}
                                                    helperText={getErrorMessage(formik.touched.yard, formik.errors.yard)}
                                                    disabled={formOtions?.yards?.length === 0 || disableYard}
                                                >
                                                    <MenuItem key="select" value={""}>
                                                        Select
                                                    </MenuItem>
                                                    {
                                                        formOtions?.yards?.map((item) => (
                                                            <MenuItem key={item.id} value={item?.id}>{item?.name}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                                <FormHelperText>
                                                    {formOtions?.yards?.length === 0 && 'No Yards Available'}
                                                </FormHelperText>
                                            </FormControl>
                                    }
                                </Grid>
                                <Grid item style={{ paddingTop: "20px" }} xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <CustomModalDatePicker
                                            name={'schedule_date'}
                                            placeholder={'select'}
                                            value={formik.values.schedule_date}
                                            label={'Schedule Date'}
                                            onChange={handleScheduleDateChange}
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched.schedule_date &&
                                                Boolean(formik.errors.schedule_date)
                                            }
                                            helperText={getErrorMessage(formik.touched.schedule_date, formik.errors.schedule_date)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {
                                        formOptionsLoading ?
                                            <Skeleton variant="rectangular" width={210} height={60} />
                                            :
                                            <FormControl fullWidth>
                                                <InputLabel id="shipping_line">Shipping Line</InputLabel>
                                                <Select
                                                    MenuProps={menuProps}
                                                    labelId="shipping_line"
                                                    id="shipping_line"
                                                    name='shipping_line'
                                                    value={formik.values.shipping_line}
                                                    label={'Shipping Line'}
                                                    onChange={handleShippingLineChange}
                                                    disabled={!formik?.values?.yard || fetchingShippingLines || shippingLinesData?.data?.shipping_lines?.length === 0}
                                                >
                                                    <MenuItem key="select" value={""}>
                                                        Select
                                                    </MenuItem>
                                                    {shippingLinesData?.data?.shipping_lines?.map((item) => (
                                                        <MenuItem key={item.id} value={item?.id}>{item?.name}</MenuItem>
                                                    ))
                                                    }
                                                </Select>
                                                <FormHelperText>
                                                    {(formik?.values?.yard && shippingLinesData?.data?.shipping_lines?.length === 0) &&
                                                        'No Shipping Lines Available for Selected Yard'}
                                                </FormHelperText>
                                            </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="bn_number"
                                            label="Booking Number"
                                            variant="outlined"
                                            name={'bn_number'}
                                            placeholder={'Enter Booking Number'}
                                            value={formik.values.bn_number}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched.bn_number && Boolean(formik.errors.bn_number)
                                            }
                                            helperText={getErrorMessage(formik.touched.bn_number, formik.errors.bn_number)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid style={{ paddingTop: "20px" }} item xs={12} md={6}>
                                    <CustomModalDatePicker
                                        disable={!formik?.values.schedule_date}
                                        selectToday={formik?.values.schedule_date}
                                        name={'validity'}
                                        placeholder={'select'}
                                        value={formik.values.validity}
                                        label={'Validity'}
                                        onChange={(value) => formik.setFieldValue('validity', value)}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.validity &&
                                            Boolean(formik.errors.validity)
                                        }
                                        helperText={getErrorMessage(formik.touched.validity, formik.errors.validity)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="container_size_type">Container Size Type</InputLabel>
                                        <Select
                                            MenuProps={menuProps}
                                            labelId="container_size_type"
                                            id="container_size_type"
                                            name="container_size_type"
                                            value={formik.values.container_size_type}
                                            label={'Container Size Type'}
                                            onChange={handleContainerSizeChange}
                                            helperText={getErrorMessage(formik.touched.container_size_type, formik.errors.container_size_type)}
                                            error={
                                                formik.touched.container_size_type &&
                                                Boolean(formik.errors.container_size_type)
                                            }
                                            disabled={!formik?.values.yard || !formik?.values?.shipping_line || fetchingContainerSizeTypes || containerSizeTypes?.data?.container_size_types?.length === 0}
                                        >
                                            <MenuItem key="select" value={""}>
                                                Select
                                            </MenuItem>
                                            {
                                                containerSizeTypes?.data?.container_size_types?.map((item) => (
                                                    <MenuItem key={item.id} value={item?.id}>{item?.container_size_type}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                        <FormHelperText>
                                            {
                                                (formik?.values.yard && formik?.values?.shipping_line && containerSizeTypes?.data?.container_size_types?.length === 0) &&
                                                'No Container Size Type avaiable for Selected Yard & Shipping Line'}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {formOptionsLoading ?
                                        <Skeleton variant="rectangular" width={210} height={60} />
                                        :
                                        <FormControl fullWidth>
                                            <InputLabel id="cargo_type">Cargo Type</InputLabel>
                                            <Select
                                                MenuProps={menuProps}
                                                labelId="cargo_type"
                                                id="cargo_type"
                                                name='cargo_type'
                                                value={formik.values.cargo_type}
                                                label={'Cargo Type'}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.cargo_type &&
                                                    Boolean(formik.errors.cargo_type)
                                                }
                                                helperText={getErrorMessage(formik.touched.cargo_type, formik.errors.cargo_type)}
                                            >
                                                <MenuItem key="select" value={""}>
                                                    Select
                                                </MenuItem>
                                                {
                                                    formOtions?.cargo_types?.map((item) => (
                                                        <MenuItem key={item.id} value={item?.id}>{item?.name}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                            <FormHelperText>
                                                {formOtions?.cargo_types?.length === 0 && 'No Cargo Types Available'}
                                            </FormHelperText>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CustomModalUploadField
                                        name="bn_image"
                                        label="Attach BN"
                                        onChange={(file) =>
                                            formik.setFieldValue('bn_image', file || "")
                                        }
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.bn_image && Boolean(formik.errors.bn_image)
                                        }
                                        helperText={
                                            formik.touched.bn_image ? formik.errors.bn_image : ''
                                        }
                                        value={formik.values.bn_image}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Button sx={{ ...BookButton }} variant="contained" type="submit">
                                        select Container
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                        <br />
                    </form>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={updatePickupApiLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </DialogContent>

            </Dialog>
            {containerList && !ContainerListApiLoading
                && <ExportContainerModalTable
                    open={showContainerTable}
                    containerType={containerSizeTypes?.data?.container_size_types?.find(ele => ele.id === formik?.values?.container_size_type)?.container_size_type}
                    containerData={containerList}
                    selectedContainers={selectedContainers}
                    setSelectedContainers={setSelectedContainers}
                    handleClose={setShowContainerTable}
                    shippingLine={shippingLinesData?.data?.shipping_lines?.find(ele => ele.id === formik?.values?.shipping_line)?.name}
                    handleClick={handleContainerSelection}
                />}
        </>
    );
}

ExportUpdatePickupModal.propTypes = {
    open: PropTypes.bool.isRequired,
    disableYard: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    initialFieldOptions: PropTypes.any.isRequired
}