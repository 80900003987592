import { createApi } from "@reduxjs/toolkit/query/react";
import AuthenticatedBaseQuery from "./AuthenticatedQuery";

export const accountApi = createApi({
  reducerPath: "accountApi",
  baseQuery: AuthenticatedBaseQuery,
  endpoints: (builder) => ({
    rechargeWallet: builder.mutation({
      query: (body) => ({
        url: `/payment/recharge-wallet/`,
        method: "POST",
        body,
      }),
    }),
    fetchWalletDetail: builder.query({
      query: () => ({
        url: `/payment/user-wallet/`,
        method: "GET",
      }),
    }),
    fetchInvoiceNumber: builder.query({
      query: () => ({
        url: `/payment/user-wallet/`,
        method: "GET",
      }),
    }),
    downloadInvoice: builder.query({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          console.log(arg);
          const response = await fetchWithBQ({
            url: `/payment/download-invoice/`,
            method: "GET",
            params: {
              order_id: arg?.order_id,
            },
            headers: {
              "Content-Type": "application/pdf",
            },
            responseHandler: (response) => response.blob(), // Convert response to blob
          });

          if (response.error) {
            return { error: response.error };
          }
          console.log(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Invoice.pdf`);

          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);

          return { data: "Download started" }; // Return a success message
        } catch (error) {
          return { error: { status: "FETCH_ERROR", error: error.message } };
        }
      },
    }),
    fetchInvoiceDetail: builder.query({
      query: (params) => ({
        url: `/payment/invoices/`,
        method: "GET",
        params: {
          ...params,
        },
      }),
    }),
    viewInvoiceDetail: builder.query({
      query: ({ order_id }) => ({
        url: `/payment/view-invoice/`,
        method: "GET",
        params: {
          order_id,
        },
      }),
    }),
    walletCallBack: builder.mutation({
      query: (body) => ({
        url: `/payment/recharge-wallet-callback/`,
        method: "POST",
        body,
      }),
    }),
    getWalletTransaction: builder.query({
      query: (params) => ({
        url: `/payment/wallet-transactions/`,
        method: "GET",
        params: {
          ...params,
        },
      }),
    }),
    getBillingTransaction: builder.query({
      query: (params) => ({
        url: `/payment/payment-transactions/`,
        method: "GET",
        params: {
          ...params,
        },
      }),
    }),
    getOtherTransporterDetails: builder.mutation({
      query: (params) => ({
        url: `/payment/payment-transactions/`,
        method: "GET",
        params: {
          ...params,
        },
      }),
    }),
    downloadFile: builder.mutation({
      query: (params) => ({
        url: `/payment/generate-transaction-excel/`,
        method: "GET",
        params,
        responseHandler: async (response) => {
          if (response?.status === 200) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            const date = new Date();
            link.href = url;
            link.download = `Invoice_Billing_History_${date
              ?.toDateString()
              ?.replaceAll(" ", "_")}`;
            link.click();

            window.URL.revokeObjectURL(url);
          } else {
            const data = await response.json()
            return data;
          }
          return null;
        },
      }),
    }),
    downloadBillingInvoiceFile: builder.mutation({
      query: (params) => ({
        url: `/payment/generate-invoice-excel/`,
        method: "GET",
        params,
        responseHandler: async (response) => {
          if (response?.status === 200) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            const date = new Date();
            link.href = url;
            link.download = `Payment_Transaction_History_${date
              ?.toDateString()
              ?.replaceAll(" ", "_")}`;
            link.click();

            window.URL.revokeObjectURL(url);
          } else {
            const data = await response.json();
            return data;
          }
          return null;
        },
      }),
    }),
  }),
});

export const {
  useLazyFetchWalletDetailQuery,
  useLazyFetchInvoiceDetailQuery,
  useLazyFetchInvoiceNumberQuery,
  useLazyViewInvoiceDetailQuery,
  useLazyGetWalletTransactionQuery,
  useLazyGetBillingTransactionQuery,
  useLazyDownloadInvoiceQuery,
  useDownloadInvoiceQuery,
  useFetchWalletDetailQuery,
  useRechargeWalletMutation,
  useWalletCallBackMutation,
  useGetOtherTransporterDetailsMutation,
  useDownloadFileMutation,
  useDownloadBillingInvoiceFileMutation,
} = accountApi;
