import { createApi } from "@reduxjs/toolkit/query/react";
import AuthenticatedBaseQuery from "./AuthenticatedQuery";

export const exportPaymentApi = createApi({
  reducerPath: "exportPaymentApi",
  baseQuery: AuthenticatedBaseQuery,
  endpoints: (builder) => ({
    createExportOrderApi: builder.mutation({
      query: (body) => ({
        url: `/payment/export/create-order/`,
        method: "POST",
        body,
      }),
    }),
    paymentInitiate: builder.mutation({
      query: (body) => ({
        url: `/payment/export/payment-initiate/`,
        method: "POST",
        body,
      }),
    }),
    paymentCallBack: builder.mutation({
      query: (body) => ({
        url: "/payment/export/payment-callback/",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useCreateExportOrderApiMutation,
  usePaymentInitiateMutation,
  usePaymentCallBackMutation,
} = exportPaymentApi;
