import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { Backdrop, Box, CircularProgress, FormControl, Grid, TextField, Typography } from '@mui/material';
import BasicSelectBox from './BasicSelectBox';
import dayjs from 'dayjs';
import LinkFileIcon from '../../Assets/icons/link-file.svg'
import { ImportFormSchema } from '../../Utils/ValidationSchemas';
import { getIn, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useGetContainerSizeTypesDataMutation, useGetShippingLinesDataMutation, useUpdateDropOffMutation } from '../../Services/ImportApi';
import BasicDateField from './BasicDateField';
import * as styles from '../../Styles/Import/formSection'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import GlobalLoader from '../../Components/GlobalLoader';

export default function ImportEditDialog({ open, handleClose, formdata, initialFieldOptions, disableYard }) {
    const { enqueueSnackbar } = useSnackbar();
    const [fetchShippingLines, { isLoading: loadingShippingLines }] = useGetShippingLinesDataMutation()
    const [fetchContainerSizeTypes, { isLoading: loadingContainerSizeTypes }] = useGetContainerSizeTypesDataMutation()
    const [updateDropOff, { isLoading: loadingUpdateDropOff }] = useUpdateDropOffMutation()
    const [shippingLines, setShippingLines] = useState([])
    const [containerSizeTypes, setContainerSizeTypes] = useState([])

    const formik = useFormik({
        initialValues: {
            yard: formdata?.yard?.id || "",
            drop_shedule_date: formdata?.schedule_date?.date || null,
            shipping_line: formdata?.shipping_line?.id || "",
            containers: formdata?.containers?.map(ele => ({ container_size_type: ele?.container_size_type?.id, container_number: ele?.container_number })) || [{ container_size_type: "", container_number: "" }],
            do_number: formdata?.do_number || "",
            do_image: formdata?.do_image.split('/').pop().trim() || ""
        },
        validationSchema: ImportFormSchema,
        onSubmit: (values, { resetForm }) => {
            handleImportUpdate(values, resetForm);
        },
    });

    useEffect(() => {
        const getShippingOptions = async () => {
            const response = await fetchShippingLines({ yard_id: formik?.values?.yard })
            if (response?.data) {
                setShippingLines(response?.data?.data?.shipping_lines)
            }
            else {
                setShippingLines([])
                enqueueSnackbar('Something went wrong! while fetching shipping lines', {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                });
            }
        }

        if (formik?.values?.yard) {
            getShippingOptions()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik?.values?.yard])
    useEffect(() => {
        const getOptions = async () => {
            if (formik?.values?.yard !== formdata?.yard?.id || formik?.values?.shipping_line !== formdata?.shipping_line?.id) {
                formik.setFieldValue('container_size_type', null)
            }
            const { data, error } = await fetchContainerSizeTypes({ yard_id: formik?.values?.yard, shipping_line_id: formik?.values?.shipping_line })
            if (data) {
                setContainerSizeTypes(data?.data?.container_size_types)
            }
            else if (error) {
                enqueueSnackbar('Something went wrong! while fetching sheduled dates', {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                });
            }
        }
        if (formdata?.yard && formdata?.shipping_line) {
            getOptions()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik?.values?.yard, formik?.values?.shipping_line])
    const handleImportUpdate = async (values, { resetForm }) => {
        const formData = new FormData();
        formData.append('yard', values.yard);
        formData.append('schedule_date', dayjs(values.drop_shedule_date).format('YYYY-MM-DD'));
        formData.append('shipping_line', values.shipping_line);
        formData.append('containers', JSON.stringify(values.containers));
        formData.append('do_number', values.do_number);
        formData.append('do_image', values.do_image);
        formData.append('id', formdata?.id);
        if (typeof (formik.values.do_image) === 'string') {
            formData.delete('do_image', values.do_image)
        }
        const { data, error } = await updateDropOff(formData)
        if (data) {
            enqueueSnackbar('Updated Succesfully !', {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });
            handleClose();
        }
        if (error) {
            enqueueSnackbar(error?.data?.message || 'Something went wrong!', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });
            console.log(error)
        }
    }

    const closeModal = () => {
        formik.resetForm()
        handleClose()
    }
    const handleFileUpload = (e) => {
        formik.setFieldTouched('do_image', true)
        formik.setFieldValue('do_image', e.target.files[0])
        e.target.value = '';
    }

    const handleSheduledDateChange = async (date) => {
        formik.setFieldValue('drop_shedule_date', date)
    }


    const handleAddContainer = () => {
        formik.setFieldValue("containers", [
            ...formik.values.containers,
            { container_size_type: "", container_number: "" },
        ]);
    };

    const handleRemoveContainer = (index) => {
        const updatedContainers = formik.values.containers.filter((_, i) => i !== index);
        formik.setFieldValue("containers", updatedContainers);
    };

    const truncateName = (name, maxLength = 10) => {
        if (name?.length > maxLength) {
            return name.substring(0, maxLength) + '...';
        }
        return name;
    }
    const handleContainerNumber = (event) => {
        const { name, value } = event.target
        if (value.length <= 11) {
            formik.setFieldValue(name, value?.toUpperCase())
        }
    }
    return (
        <Dialog
            open={open}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={"lg"}
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '16px'
                }
            }}
        >
            <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
                {"Edit Import Drop-off Details"}
            </DialogTitle>
            <DialogContent sx={{
                margin: '1rem', padding: {
                    xs: "20px 0px",
                    sm: "20px 0px",
                    md: "20px 24px",
                    lg: "20px 24px",
                    xl: "20px 24px",
                }
            }}>
                <form onSubmit={formik.handleSubmit}>
                    <Box sx={{ marginTop: '10px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <BasicSelectBox
                                    label='Yard'
                                    name="yard"
                                    fieldValue={formik?.values?.yard}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    fieldTouched={formik.touched.yard}
                                    fieldError={formik.errors.yard}
                                    options={initialFieldOptions?.yards}
                                    disabled={initialFieldOptions?.yards?.length === 0 || disableYard}
                                    optionError={initialFieldOptions?.yards?.length === 0 && 'No Yards Available'}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <BasicDateField
                                        id="drop_shedule_date"
                                        name="drop_shedule_date"
                                        label="Scheduled Date"
                                        value={formik.values.drop_shedule_date}
                                        handleChange={handleSheduledDateChange}
                                        fieldTouched={formik.touched.drop_shedule_date}
                                        fieldError={formik.errors.drop_shedule_date}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <BasicSelectBox
                                    label='Shipping Line'
                                    name="shipping_line"
                                    fieldValue={formik?.values?.shipping_line}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    fieldTouched={formik.touched.shipping_line}
                                    fieldError={formik.errors.shipping_line}
                                    options={shippingLines}
                                    disabled={shippingLines?.length === 0}
                                    optionError={shippingLines?.length === 0 && 'No Shipping Lines Available for Selected Yard'}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>

                                <TextField
                                    id="do_number"
                                    name="do_number"
                                    label="Do number"
                                    placeholder='DO Number'
                                    value={formik.values.do_number}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.do_number && Boolean(formik.errors.do_number)
                                    }
                                    sx={{
                                        background: '#fff', width: '100%', borderRadius: '4px',
                                        // border: (formik.touched.do_number && formik.errors.do_number) ? '1px solid red' : '0px'
                                    }}
                                />
                                {formik.touched.do_number && formik.errors.do_number && <Typography sx={styles.formFieldError}>{formik.errors.do_number}</Typography>}
                            </Grid>
                            {formik.values.containers?.map((container, index) => (
                                <>
                                    <Grid item xs={12} md={6}>
                                        <BasicSelectBox
                                            label='Container Size Type'
                                            id={`containers.${index}.container_size_type`}
                                            name={`containers.${index}.container_size_type`}
                                            text="Container Size Type"
                                            fieldValue={formik.values.containers[index].container_size_type}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            fieldTouched={getIn(formik.touched, `containers.${index}.container_size_type`)}
                                            fieldError={getIn(formik.errors, `containers.${index}.container_size_type`)}
                                            options={containerSizeTypes}
                                            disabled={containerSizeTypes?.length === 0}
                                            optionError={
                                                (containerSizeTypes?.length === 0) &&
                                                'No Container Size Type avaiable for Selected Yard & Shipping Line'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {index !== 0 && <Typography>
                                            <RemoveCircleOutlineIcon
                                                sx={{ float: "right", color: "red", cursor: "pointer", width: "18px", marginTop: "-23px" }}
                                                onClick={() => handleRemoveContainer(index)} />
                                        </Typography>}
                                        <TextField
                                            fullWidth
                                            id={`containers.${index}.container_number`}
                                            name={`containers.${index}.container_number`}
                                            label="Container Number"
                                            placeholder='Enter Conatiner Number'
                                            value={formik.values.containers[index].container_number}
                                            onChange={handleContainerNumber}
                                            onBlur={formik.handleBlur}
                                            error={
                                                getIn(formik.touched, `containers.${index}.container_number`)
                                                && Boolean(getIn(formik.errors, `containers.${index}.container_number`))
                                            }
                                            sx={{
                                                background: '#fff', borderRadius: '4px',
                                            }}
                                        />
                                        {getIn(formik.touched, `containers.${index}.container_number`)
                                            && getIn(formik.errors, `containers.${index}.container_number`)
                                            && <Typography sx={styles.formFieldError}>{getIn(formik.errors, `containers.${index}.container_number`)}</Typography>}
                                    </Grid>
                                </>
                            ))}
                            <Grid item xs={12} md={12}>
                                <label
                                    htmlFor="fileInput2"
                                    style={{
                                        display: 'flex', alignItems: 'center',
                                        // background: '#F3F5F8',
                                        padding: '0.7rem 1rem', width: '100%', borderRadius: '4px', cursor: 'pointer', color: '#000000',
                                        // border: (formik.touched.do_image && formik.errors.do_image) ? '1px solid red' : '0px'
                                        border: '1px solid #b1b1b1'
                                    }}>
                                    <input id="fileInput2" type="file" onChange={handleFileUpload} style={{ display: 'none' }} />
                                    <Box sx={{
                                        display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 1,
                                        background: '#ECECEC 0% 0% no-repeat padding-box',
                                        p: '0.4rem',
                                        borderRadius: '8px'
                                    }}>
                                        <img src={LinkFileIcon} height='20px' width='20px' alt="file icon" />
                                    </Box>
                                    <Typography variant="body1">
                                        {formik.values.do_image ? truncateName(formik.values.do_image?.name) || truncateName(formik.values.do_image) : 'Attach Import DO'}</Typography>
                                </label>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container spacing={4} marginTop={"0px"}>
                        <Grid item xs={12} md={6} paddingTop={"0px important"}>
                            <Button
                                // onClick={handleClose}
                                variant='contained' color='primary' sx={{
                                    width: '100%',
                                    background: "#933118",
                                    // mt: '2rem',
                                    "&.MuiButton-root:hover": {
                                        backgroundColor: "#6e1e0a !important",
                                    },
                                }}
                                onClick={handleAddContainer}
                                type='button'
                            >
                                Add More Containers
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6} paddingTop={"0px important"}>
                            <Button
                                // onClick={handleClose}
                                variant='contained' color='primary' sx={{
                                    width: '100%',
                                    background: "#933118",
                                    // mt: '2rem',
                                    "&.MuiButton-root:hover": {
                                        backgroundColor: "#6e1e0a !important",
                                    },
                                }}
                                type='submit'
                            >
                                Update details
                            </Button>
                        </Grid>
                    </Grid>

                </form>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loadingUpdateDropOff}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GlobalLoader open={Boolean(loadingShippingLines || loadingContainerSizeTypes)} />
            </DialogContent>
        </Dialog>
    );
}

ImportEditDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    disableYard: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    formdata: PropTypes.any.isRequired,
    initialFieldOptions: PropTypes.any.isRequired
}