import zIndex from "@mui/material/styles/zIndex";

const navbarContainer = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "96%",
  margin: "0 auto",
  height: {
    xs: "80px",
    sm: "80px",
    md: "100px",
    lg: "100px",
    xl: "100px",
  },
  padding: ".5rem 0",
};

const brandTitle = {
  fontFamily: "Open Sans",
  fontWeight: "bold",
  fontSize: {
    xs: "18px",
    sm: "20px",
    md: "22px",
    lg: "26px",
    xl: "28px",
  },
  lineHeight: {
    xs: "30px",
    sm: "32px",
    md: "38px",
    lg: "42px",
    xl: "45px",
  },
  color: "#000000",
};

const brandSubTitle = {
  fontFamily: "Open Sans",
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "14px",
    lg: "18px",
    xl: "18px",
  },
  letterSpacing: "5.56px",
  color: "#000000",
};

const navLinkWrapper = {
  display: "flex",
  alignItems: "center",
  gap: {
    xs: "1rem",
    sm: "1.5rem",
    md: "1.8rem",
    lg: "2rem",
    xl: "2.5rem",
  },
  height: "100%",
};

const navItemWrapper = {
  height: "100%",
  display: "flex",
  alignItems: "center",
};

const navItem = {
  fontFamily: "Open Sans",
  fontSize: {
    xs: "12px",
    sm: "12px",
    md: "14px",
    lg: "18px",
    xl: "20px",
  },
  font: "normal normal 600 25px/ 34px Open Sans",
  letterSpacing: "0px",
  color: "#000000",
  textTransform: "uppercase",
  cursor: "pointer",
  ":hover": {
    color: "#933118",
  },
};

const navbarDropdownContainer = {
  position: "absolute",
  top: "80%",
  backgroundColor: "#fff",
  boxShadow: "0 3px 12px rgba(0, 0, 0, 0.15)",
  padding: "1rem 0rem",
  minWidth: "180px",
  transform: "translateX(-25%)",
  textAlign: "center",
  zIndex: 999,
};

const navbarDropdownItem = {
  fontWeight: 600,
  fontSize: "14px",
  fontFamily: "Open Sans",
  lineHeight: "26px",
  ":hover": {
    color: "#933118",
  },
};

const navButtonsWrapper = {
  display: "flex",
  alignItems: "center",
};

const navLogoutButton = {
  fontWeight: "medium",
  textTransform: "none",
  fontSize: "18px",
  background: "#933118 0% 0% no-repeat padding-box",
  borderRadius: "30px",
};

export {
  navbarContainer,
  brandTitle,
  brandSubTitle,
  navLinkWrapper,
  navItemWrapper,
  navItem,
  navbarDropdownContainer,
  navbarDropdownItem,
  navButtonsWrapper,
  navLogoutButton,
};
