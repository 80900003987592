import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, FormControl, FormHelperText, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import HeaderSection from '../../Components/HeaderSection'
import * as styles from '../../Styles/Import/formSection'
import dayjs from 'dayjs';
import ImportsTable from './ImportsTable';
import { getIn, useFormik } from "formik";
import { useAddImportContainerDetailsMutation, useFetchImportContainerDetailsMutation, useGetInitialFormDataMutation, useGetShippingLinesDataMutation, useGetContainerSizeTypesDataMutation } from '../../Services/ImportApi'
import DateField from './DateField'
import LinkFileIcon from '../../Assets/icons/link-file.svg'
import { ImportFormSchema } from '../../Utils/ValidationSchemas';
import { useSnackbar } from 'notistack';
import { useCreateImportOrderApiMutation } from '../../Services/ImportPaymentApi';
import { useNavigate } from 'react-router-dom';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Helmet } from 'react-helmet-async';
import GlobalLoader from '../../Components/GlobalLoader';
import CustomSelectField from '../../Components/CustomSelectField';
import { FormInputField, FormLabelStyle } from '../../Styles/Form/Field';
import AddNewTransporterModal from '../../Components/AddNewTransporterModal';

const ImportForm = () => {
    const navigate = useNavigate();


    const [sortType, setSortType] = useState('asc');
    const [billToModal, setBillToModal] = useState(false);
    const handleSortChange = (e) => {
        setSortType(e.target.value)
    }

    const [createOrderApi, { isLoading: createOrderApiLoading }] = useCreateImportOrderApiMutation();
    const [getInitialFormData, { isLoading, data: initialFormData }] = useGetInitialFormDataMutation()
    const [handleAddImportData, { isLoading: addImportDataLoading }] = useAddImportContainerDetailsMutation()
    const [fetchShippingLines, { isLoading: loadingShippingLines, data: shippingLinesData }] = useGetShippingLinesDataMutation()
    const [fetchContainerSizeTypes, { isLoading: loadingContainerSizeTypes, data: containerSizeTypes }] = useGetContainerSizeTypesDataMutation()

    const [importContainerDetails, { isLoading: fetchingImportDetails }] = useFetchImportContainerDetailsMutation()

    const [containerDetails, setContainerDetails] = useState([])

    const handleContainerDetails = async () => {
        const res = await importContainerDetails(sortType)
        setContainerDetails(res?.data)
    }

    const { enqueueSnackbar } = useSnackbar();

    const formik = useFormik({
        initialValues: {
            yard: "",
            drop_shedule_date: null,
            shipping_line: "",
            containers: [{ container_size_type: "", container_number: "" }],
            do_number: "",
            do_image: ""
        },
        validationSchema: ImportFormSchema,
        onSubmit: (values, { resetForm }) => {
            handleImportFormSubmit(values, resetForm);
        },
    });

    useEffect(() => {
        getInitialFormData()
        handleContainerDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortType])

    useEffect(() => {
        if (formik?.values?.yard) {
            fetchShippingLines({ yard_id: formik?.values?.yard })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik?.values?.yard])

    useEffect(() => {
        if (containerDetails?.data?.length > 0) {
            formik.setFieldValue('yard', containerDetails?.data[0].yard?.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerDetails])


    useEffect(() => {
        if (formik?.values?.yard && formik?.values?.shipping_line) {
            formik.setFieldValue("containers", [
                ...formik.values.containers.map(ele => ({
                    container_size_type: "", container_number: ""
                })),
            ]);
            fetchContainerSizeTypes({ yard_id: formik?.values?.yard, shipping_line_id: formik?.values?.shipping_line })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik?.values?.yard, formik?.values?.shipping_line])

    const handleImportFormSubmit = async (values, resetForm) => {
        const formData = new FormData();
        formData.append('yard', values.yard);
        formData.append('schedule_date', dayjs(values.drop_shedule_date).format('YYYY-MM-DD'));
        formData.append('shipping_line', values.shipping_line);
        formData.append('containers', JSON.stringify(values.containers));
        formData.append('do_number', values.do_number);
        formData.append('do_image', values.do_image);

        const response = await handleAddImportData(formData)
        if (response.data) {
            enqueueSnackbar('Import Drop Off Details Added Successfully!', {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });
            handleContainerDetails()
            resetForm()
        }

        else if (response?.error?.status === 400) {
            enqueueSnackbar(response?.error?.data?.message || 'Something went wrong! while uploading data', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });
        }
        else {
            enqueueSnackbar('Something went wrong! while uploading data', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });
        }
    }

    const handleFileUpload = (e) => {
        formik.setFieldTouched('do_image', true);
        formik.setFieldValue('do_image', e.target.files[0]);
        e.target.value = '';
    }

    const handleSheduledDateChange = async (date) => {
        formik.setFieldValue('drop_shedule_date', date)
    }

    const handleSubmit = async () => {
        setBillToModal(true)
        // const { data, error } = await createOrderApi({ operation_type: "import", dropoffs: containerDetails?.data?.map(ele => ele.id) })
        // if (data) {
        //     navigate('/billing/billing', { state: { orderId: data?.data?.order_id, converted_order_id: data?.data?.converted_order_id, mode: "import", ...containerDetails?.summary } })
        // }
        // if (error) {
        //     enqueueSnackbar('Something went wrong !', {
        //         variant: 'error',
        //         anchorOrigin: { vertical: 'top', horizontal: 'center' },
        //     });
        //     console.log(error)
        // }
    }

    const handlePageChange = async (page) => {
        const res = await importContainerDetails({ page })
        setContainerDetails(res?.data)
    }

    const handleAddContainer = () => {
        formik.setFieldValue("containers", [
            ...formik.values.containers,
            { container_size_type: "", container_number: "" },
        ]);
    };

    const handleRemoveContainer = (index) => {
        const updatedContainers = formik.values.containers.filter((_, i) => i !== index);
        formik.setFieldValue("containers", updatedContainers);
    };

    const handleContainerNumber = (event) => {
        const { name, value } = event.target
        if (value.length <= 11) {
            formik.setFieldValue(name, value?.toUpperCase())
        }
    }

    const getErrorMessage = (touched, error) => touched ? error : '';
    const formatTime = (time) => {
        if (!time) return ''; // Check if time is undefined
        const [hours, minutes] = time.split(':');
        const hoursNum = parseInt(hours);
        const period = hoursNum >= 12 ? 'PM' : 'AM';
        const formattedHours = hoursNum % 12 === 0 ? 12 : hoursNum % 12;
        return `${formattedHours}:${minutes}${period}`;
    };
    const transformOptions = (name, options) => {
        if (options && (name === 'yard' || name === 'shipping_line')) {
            return options?.reduce((acc, { id, name }) => {
                acc.push({ id, name: name });
                return acc;
            }, []);
        }

        else if (options && name.includes('container_size_type')) {
            return options?.reduce((acc, { id, container_size_type }) => {
                acc.push({ id, name: container_size_type });
                return acc;
            }, []);
        }

        else if (options && name === 'time_slot') {
            return options?.reduce((acc, { id, start_time, end_time }) => {
                acc.push({ id, name: `${formatTime(start_time)} - ${formatTime(end_time)}` });
                return acc;
            }, []);
        }

        else if (options && name.includes('container_number')) {
            if (options.error) return [];
            return options?.map(({ id, container_number }) => ({ id: container_number, name: container_number }));
        }
        return []
    };

    const handleSaveBillGenrationDetails = async (values) => {
        const formData = new FormData()
        values && Object.keys(values)?.forEach(key => {
            formData.append(key, values[key])
        })
        formData.append('operation_type', "import")
        containerDetails?.data?.forEach(ele => {
            formData.append('dropoffs', ele.id)
        })
        const { data, error } = await createOrderApi(formData)
        if (data) {
            setBillToModal(false)
            navigate('/billing/billing', { state: { orderId: data?.data?.order_id, converted_order_id: data?.data?.converted_order_id, mode: "import", ...containerDetails?.summary } })
        }
        if (error) {
            enqueueSnackbar(error?.data?.message || 'Something went wrong !', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });
            console.log(error)
        }
    }

    return (
        <Box>
            <Helmet>
                <title>Import-Drop-Off | Gati se Pragati</title>
            </Helmet>
            <HeaderSection title="IMPORT-DROP-OFF" />
            <Box sx={styles.formContainer}>
                <Typography sx={styles.formTitle}>
                    Import-Drop-Off Details Form
                </Typography>

                <form onSubmit={formik.handleSubmit}>

                    <Grid container spacing={5} sx={{ mt: '0px' }}>
                        <Grid item xs={12} md={6}>
                            <CustomSelectField
                                name={'yard'}
                                required={true}
                                placeholder={'select'}
                                value={formik.values.yard}
                                label={'Yard'}
                                onChange={formik.handleChange}
                                options={transformOptions('yard', initialFormData?.data?.yards)}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.yard &&
                                    Boolean(formik?.errors?.yard)
                                }
                                helperText={getErrorMessage(formik.touched.yard, formik.errors.yard)}
                                disable={initialFormData?.data?.yards?.length === 0 || containerDetails?.data?.length > 0}
                                optionError={initialFormData?.data?.yards?.length === 0 && 'No Yards avaiable '}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateField
                                required
                                id="drop_shedule_date"
                                name="drop_shedule_date"
                                text="Drop Scheduled Date"
                                value={formik.values.drop_shedule_date}
                                handleChange={handleSheduledDateChange}
                                fieldTouched={formik.touched.drop_shedule_date}
                                fieldError={formik.errors.drop_shedule_date}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomSelectField
                                required={true}
                                name={'shipping_line'}
                                placeholder={'select'}
                                value={formik.values.shipping_line}
                                label={'Shipping Line'}
                                onChange={formik.handleChange}
                                options={transformOptions('shipping_line', shippingLinesData?.data?.shipping_lines)}
                                disable={(!formik.values.yard || loadingShippingLines || shippingLinesData?.data?.shipping_lines?.length === 0)}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.shipping_line &&
                                    Boolean(formik.errors.shipping_line)
                                }
                                helperText={
                                    formik.touched.shipping_line
                                        ? formik.errors.shipping_line
                                        : ''
                                }
                                optionError={
                                    (formik.values.yard && shippingLinesData?.data?.shipping_lines?.length === 0)
                                    && 'No Shipping Lines avaiable for Selected Yard'}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{ ...FormLabelStyle }}>Import DO Number <span style={{ color: "red" }}>*</span></Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                // type="number"
                                sx={{
                                    ...FormInputField,
                                }}
                                id="do_number"
                                name="do_number"
                                placeholder='Enter Do Number'
                                value={formik.values.do_number}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.do_number && Boolean(formik.errors.do_number)
                                }
                            />
                            {formik.touched.do_number && formik.errors.do_number && <FormHelperText sx={{ marginLeft: '14px' }} error>
                                {formik.errors.do_number}
                            </FormHelperText>
                            }
                        </Grid>
                        {formik.values.containers?.map((container, index) => (
                            <>
                                <Grid item xs={12} md={6}>
                                    <CustomSelectField
                                        required={true}
                                        id={`containers.${index}.container_size_type`}
                                        name={`containers.${index}.container_size_type`}
                                        placeholder={'select'}
                                        value={formik.values.container_size_type}
                                        label={"Container Size Type"}
                                        onChange={formik.handleChange}
                                        options={
                                            transformOptions("container_size_type", containerSizeTypes?.data?.container_size_types) || []
                                        }
                                        disable={(!formik.values.yard || loadingContainerSizeTypes || !formik.values.shipping_line || containerSizeTypes?.data?.container_size_types?.length === 0)}
                                        onBlur={formik.handleBlur}
                                        error={
                                            Boolean(getIn(formik.errors, `containers.${index}.container_size_type`))
                                        }
                                        helperText={
                                            getIn(formik.errors, `containers.${index}.container_size_type`)
                                        }
                                        optionError={
                                            (formik.values.yard && formik.values.shipping_line && containerSizeTypes?.data?.container_size_types?.length === 0) &&
                                            'No Container Size Type avaiable for Selected Yard & Shipping Line'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography sx={{ ...FormLabelStyle }}>Container Number <span style={{ color: "red" }}>*</span>
                                        <RemoveCircleOutlineIcon
                                            sx={{ float: "right", color: "red", cursor: "pointer", visibility: index === 0 ? "hidden" : "visible" }}
                                            onClick={() => handleRemoveContainer(index)} />
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        // type="number"
                                        sx={{
                                            ...FormInputField,
                                        }}
                                        id={`containers.${index}.container_number`}
                                        name={`containers.${index}.container_number`}
                                        placeholder='Enter Do Number'
                                        value={formik.values.containers[index].container_number}
                                        onChange={handleContainerNumber}
                                        onBlur={formik.handleBlur}
                                        error={
                                            getIn(formik.touched, `containers.${index}.container_number`) && Boolean(getIn(formik.errors, `containers.${index}.container_number`))
                                        }
                                    />
                                    {getIn(formik.touched, `containers.${index}.container_number`) && getIn(formik.errors, `containers.${index}.container_number`)
                                        && <FormHelperText sx={{ marginLeft: '14px' }} error>
                                            {getIn(formik.errors, `containers.${index}.container_number`)}
                                        </FormHelperText>
                                    }
                                </Grid>
                            </>
                        ))}
                        <Grid item xs={12} md={12}>
                            <Typography sx={styles.formFieldHeadings}>Attach Import DO <span style={{ color: "red" }}>*</span></Typography>
                            <label
                                htmlFor="fileInput"
                                style={{
                                    display: 'flex', alignItems: 'center', background: '#F3F5F8', padding: '0.7rem 1rem', width: '100%', borderRadius: '8px', cursor: 'pointer', color: '#000000',
                                    border: (formik.touched.do_image && formik.errors.do_image) ? '1px solid red' : '0px'
                                }}>
                                <input id="fileInput" type="file" onChange={handleFileUpload} style={{ display: 'none' }} />
                                <Box sx={{
                                    display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 1,
                                    background: '#ECECEC 0% 0% no-repeat padding-box', p: '0.4rem',
                                    borderRadius: '8px'
                                }}>
                                    <img src={LinkFileIcon} height='20px' width='20px' alt="file icon" />
                                </Box>
                                <Typography variant="body1">{formik.values.do_image ? formik.values.do_image.name : 'Attach Import DO'}</Typography>
                            </label>
                            {formik.touched.do_image && formik.errors.do_image && <Typography sx={styles.formFieldError}>{formik.errors.do_image}</Typography>}
                        </Grid>
                    </Grid>
                    <Box sx={{ marginTop: '16px' }}></Box>
                    <Grid container spacing={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 5 }} >
                        <Grid item xs={12} md={6}>
                            <Button fullWidth disableElevation
                                size='large' variant='contained' type='button'
                                onClick={handleAddContainer}
                                sx={styles?.addDetailsButton}
                            >Add More Containers</Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button fullWidth disableElevation
                                size='large' variant='contained' type='submit'
                                disabled={!formik.dirty}
                                sx={styles?.addDetailsButton}
                            >Add Another Delivery Order</Button>
                        </Grid>
                    </Grid>
                </form>

            </Box>
            <Box sx={{ background: '#F5F5F5', m: 'auto', mt: '3rem', padding: '2rem 0rem' }}>
                <Box sx={{ ...styles.formContainer }}>
                    {
                        (fetchingImportDetails) ?
                            <Box sx={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '500px'
                            }}>
                                <CircularProgress />
                            </Box>
                            :
                            <>
                                <Typography sx={{
                                    fontWeight: 500, mt: '0.5rem', marginBottom: "0.5rem", fontSize: {
                                        xs: '14px',
                                        sm: '14px',
                                        md: '14px',
                                        lg: '16px',
                                        xl: '18px',
                                    }
                                }}>Sort by</Typography>
                                <FormControl>
                                    <Select
                                        IconComponent={KeyboardArrowDownIcon}
                                        size='small'
                                        sx={{
                                            '& .MuiSelect-select': {
                                                fontSize: {
                                                    xs: '14px',
                                                    sm: '14px',
                                                    md: '14px',
                                                    lg: '16px',
                                                    xl: '18px',
                                                },
                                            },
                                            minWidth: '250px',
                                            mb: '2rem',
                                            backgroundColor: '#fff',
                                            color: 'grey'
                                        }}
                                        id="demo-simple-select"
                                        value={sortType}
                                        onChange={handleSortChange}
                                    >
                                        <MenuItem value={'asc'}>Ascending</MenuItem>
                                        <MenuItem value={'desc'}>Descending</MenuItem>
                                    </Select>
                                </FormControl>
                                <ImportsTable
                                    initialFieldOptions={initialFormData?.data || {}}
                                    containerDetails={containerDetails}
                                    handleContainerDetails={handleContainerDetails}
                                    summary={containerDetails?.summary}
                                    handlePageChange={handlePageChange}
                                />
                                <Button fullWidth disableElevation
                                    size='large' variant='contained' type='button'
                                    onClick={handleSubmit}
                                    sx={styles.submitDetailsButton}
                                    disabled={!containerDetails?.data || containerDetails?.data?.length === 0}
                                >Submit</Button>
                            </>

                    }
                </Box>
            </Box>
            {billToModal &&
                <AddNewTransporterModal
                    open={billToModal}
                    apiLoading={createOrderApiLoading}
                    defaultValue={containerDetails?.third_party_user_data}
                    closeModal={() => setBillToModal(false)}
                    handleSaveBillGenrationDetails={handleSaveBillGenrationDetails}
                />
            }
            <GlobalLoader open={Boolean(isLoading || addImportDataLoading)} />
        </Box>
    )
}

export default ImportForm;
