import { createApi } from "@reduxjs/toolkit/query/react";
import AuthenticatedBaseQuery from "./AuthenticatedQuery";

export const importPaymentApi = createApi({
  reducerPath: "importPaymentApi",
  baseQuery: AuthenticatedBaseQuery,
  endpoints: (builder) => ({
    createImportOrderApi: builder.mutation({
      query: (body) => ({
        url: `/payment/import/create-order/`,
        method: "POST",
        body,
      }),
    }),
    importPaymentInitiate: builder.mutation({
      query: (body) => ({
        url: `/payment/import/payment-initiate/`,
        method: "POST",
        body,
      }),
    }),
    importPaymentCallBack: builder.mutation({
      query: (body) => ({
        url: "/payment/import/payment-callback/",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useCreateImportOrderApiMutation,
  useImportPaymentCallBackMutation,
  useImportPaymentInitiateMutation,
} = importPaymentApi;
