import React from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { FormLabelStyle } from '../../Styles/Form/Field';

const DateField = ({ id, name, text, required, value, disabled, handleChange, sheduledDates, sheduledDatesLoading, fieldTouched, fieldError }) => {

    // const listOfSheduledDates = sheduledDates?.map(date => date.date)

    return (
        <FormControl fullWidth error={fieldTouched && fieldError}>
            <LocalizationProvider sx={{ width: '100%' }} dateAdapter={AdapterDayjs}>
                <Typography
                    sx={{ ...FormLabelStyle }}
                >
                    {text} {required && <span style={{ color: "red" }}>*</span>}
                </Typography>
                <DemoContainer components={['DesktopDatePicker']} sx={{ padding: '0px', width: "100%" }}>
                    <DesktopDatePicker
                        error={fieldTouched && fieldError}
                        id={id}
                        name={name}
                        disabled={disabled}
                        format='DD-MM-YYYY'
                        onChange={(date) => handleChange(date)}
                        value={value ? dayjs(value) : null}
                        slotProps={{
                            textField: {
                                placeholder: "Select Date",
                                InputProps: {
                                    disabled: true, // Prevent manual input
                                    sx: {
                                        fontSize: {
                                            xs: '14px',
                                            sm: '14px',
                                            md: '14px',
                                            lg: '16px',
                                            xl: '18px',
                                        },
                                    }
                                },
                            },
                        }}
                        sx={{
                            backgroundColor: '#F3F5F8',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: () => `${(fieldTouched && fieldError) ? "1px solid red" : "none"}`,
                                borderRadius: '8px'
                            },
                            width: '100%',
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                        }}
                        shouldDisableDate={(date) => dayjs(date).isBefore(dayjs(), 'day')}
                    />
                </DemoContainer>
            </LocalizationProvider>
            <FormHelperText>{fieldTouched && fieldError && `${text} is Required`}</FormHelperText>
        </FormControl>
    )
}

DateField.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    text: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    sheduledDates: PropTypes.array,
    sheduledDatesLoading: PropTypes.bool,
    fieldTouched: PropTypes.bool,
    fieldError: PropTypes.string,
    value: PropTypes.string
}

export default DateField