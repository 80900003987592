import { Box, Chip, Typography } from '@mui/material'
import React from 'react'
import { ButtonStyle, HeaderStyle, SubHeaderstyle } from '../../Styles/componentStyles/ServicesSection'
import { useLocation, useNavigate } from 'react-router-dom';
import PaymentFailedIcon from '../../Assets/icons/payement-failed-icon.svg'
import { Helmet } from 'react-helmet-async';

const PaymentFailed = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search);
  return (
    <Box sx={{ flexGrow: "1" }}>
      <Helmet>
        <title>Payment | Gati se Pragati</title>
      </Helmet>
      <Box sx={{
        maxWidth: {
          xs: "90%",
          sm: "80%",
          md: "70%",
          lg: "70%",
          xl: "70%",
        }, margin: " 4rem auto "
      }}>
        <Box sx={{
          width: {
            xs: "100%",
            sm: "80%",
            md: "60%",
            lg: "40%",
            xl: "40%",
          }, margin: "auto"
        }}>
          <Typography textAlign={'center'} sx={{
            "img": {
              width: {
                xs: "40px",
                sm: "40px",
                md: "80px",
                lg: "80px",
                xl: "80px",
              }
            }
          }}>
            <img src={PaymentFailedIcon} alt="payment failed" height={'80px'} width={'80px'} />
          </Typography>
          <Typography sx={{ ...HeaderStyle, margin: "1rem 0px 2rem 0px" }} textAlign={'center'}>
            PAYMENT FAILED
          </Typography>
          <Typography sx={SubHeaderstyle} textAlign={'center'}>Your Order Id is {searchParams?.get('transactionid')}</Typography>
          {/* <Typography sx={TextStyle} textAlign={'center'}>Receipt of Booking</Typography> */}
          {/* <Box sx={{margin:"1rem 0 4rem 0"}}>
                <Box sx={{display:"flex", margin:"auto", width:"80%", justifyContent:"space-between"}}>
                <Chip sx={{...ButtonStyle,background: '#933118 0% 0% no-repeat padding-box',}} label="Download" />
                </Box>
                </Box> */}
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1.5rem 0rem' }}>
            <Box sx={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
              <Chip sx={{ ...ButtonStyle, color: "#933118" }} label="Back to Home" variant="outlined" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PaymentFailed