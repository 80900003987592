import HeroBgImage from "../../Assets/images/header-image-large.webp";

const ServicesContainer = {
  height: {
    xs: "20dvh",
    sm: "25dvh",
    md: "30dvh",
    lg: "35dvh",
    xl: "40dvh",
  },
  backgroundImage: `url(${HeroBgImage})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const ServicesContentContainer = {
  textAlign: "center",
  height: {
    xs: "150px",
    sm: "200px",
    md: "250px",
    lg: "300px",
    xl: "400px",
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const ServicesHeader = {
  fontWeight: "bold",
  fontSize: {
    xs: "18px",
    sm: "25px",
    md: "32px",
    lg: "40px",
    xl: "50px",
  },
  lineHeight: "75px",
  fontFamily: "Oswald",
  color: "white",
};

export { ServicesContainer, ServicesContentContainer, ServicesHeader };
