import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import { FormHelperText } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const CustomModalDatePicker = ({
  options,
  disable,
  label,
  selectToday,
  name,
  value,
  onChange,
  onBlur,
  error,
  helperText,
}) => {
  const dates = options?.map((ele) => ele.date);
  const handleDisable = (date) => {
    if (!options) {
      return selectToday ? !dayjs(date).isAfter(dayjs(selectToday, "YYYY-MM-DD"), 'day') : dayjs(date).isBefore(dayjs(), 'day');
    }
    return !dates.includes(dayjs(date).format('YYYY-MM-DD'));
  };
  const handleChange = (value) => {
    const id = options?.find(
      (ele) =>
        dayjs(ele.date).format('YYYY-MM-DD') ===
        dayjs(value).format('YYYY-MM-DD')
    )?.id;
    onChange(dayjs(value).format('YYYY-MM-DD'), id);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DesktopDatePicker']} sx={{ padding: '5px' }} >
        <DesktopDatePicker
          label={label}
          disabled={disable}
          name={name}
          onChange={handleChange}
          value={value ? dayjs(value) : null}
          format='DD-MM-YYYY'
          slotProps={{
            textField: {
              placeholder: "Select Date",
              InputProps: {
                disabled: true, // Prevent manual input
                sx: {
                  fontSize: {
                    xs: '12px',
                    sm: '14px',
                    md: '14px',
                    lg: '16px',
                    xl: '18px',
                  },
                }
              },
            },
          }}
          sx={{
            borderRadius: {
              xs: '2px',
              sm: '4px',
              md: '4px',
              lg: '6px',
              xl: '8px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: () => `${(error) ? "1px solid red" : "1px solid lightgrey"}`,
              borderRadius: {
                xs: '2px',
                sm: '4px',
                md: '4px',
                lg: '6px',
                xl: '8px',
              }
            },
            width: '100%',
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
          }}
          onBlur={onBlur}
          shouldDisableDate={handleDisable}
        />
      </DemoContainer>
      {helperText && error && (
        <FormHelperText sx={{ marginLeft: '14px' }} error>
          {helperText}
        </FormHelperText>
      )}
    </LocalizationProvider>
  );
};

CustomModalDatePicker.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any),
  disable: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  selectToday: PropTypes.string,
  helperText: PropTypes.string,
};
export default CustomModalDatePicker;
