import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormLabelStyle } from '../Styles/Form/Field';
import PropTypes from 'prop-types';
import { FormHelperText, Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
const FilterCustomDateTimePicker = ({
  options,
  disable,
  label,
  name,
  value,
  onChange,
  onBlur,
  error,
  helperText,
  required,
  allDates,
}) => {
  const dates = options?.map((ele) => ele.date);
  const handleDisable = (date) => {
    if (allDates) {
      return false;
    }
    if (!options && !allDates) {
      return !dayjs(date).isAfter(dayjs(), 'day');
    }
    return !dates.includes(dayjs(date).format('YYYY-MM-DD'));
  };
  const handleChange = (value) => {
    const id = options?.find(
      (ele) =>
        dayjs(ele.date).format('YYYY-MM-DD') ===
        dayjs(value).format('YYYY-MM-DD')
    )?.id;
    onChange(dayjs(value).format('YYYY-MM-DD'), id);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Typography
        sx={{ ...FormLabelStyle, ...(disable && { color: 'grey !important' }) }}
      >
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <DemoContainer components={['DesktopDatePicker']} sx={{ padding: '0px', width: "100%" }}>
        <DesktopDatePicker
          format='DD-MM-YYYY'
          disabled={disable}
          name={name}
          onChange={handleChange}
          value={value?dayjs(value):null}
          onBlur={onBlur}
          slotProps={{
            textField: {
              placeholder: "Select Date",
              InputProps: {
                sx: {
                  fontSize: {
                    xs: '14px',
                    sm: '14px',
                    md: '14px',
                    lg: '16px',
                    xl: '18px',
                  },
                }
              },
            },
          }}
          sx={{
            backgroundColor: '#fff',
            color: "#878787",
            borderRadius: '8px',
            '& .MuiOutlinedInput-notchedOutline': {
              border: () => `${(error) ? "1px solid red" : "none"}`,
              borderRadius: '8px'
            },
            minWidth: "100% !important",
            width: '100%'
          }}
          shouldDisableDate={handleDisable}
        />
      </DemoContainer>
      {helperText && error && (
        <FormHelperText sx={{ marginLeft: '14px' }} error>
          {helperText}
        </FormHelperText>
      )}
    </LocalizationProvider>
  );
};

FilterCustomDateTimePicker.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any),
  allDates: PropTypes.bool,
  disable: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
};
export default FilterCustomDateTimePicker;
