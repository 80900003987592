import { Box, CircularProgress, Typography } from "@mui/material";
import * as styles from '../../Styles/HomePage/heroSection';
import BackgroundVideo from '../../Assets/video/GSP.mp4';

// import GooglePlayIcon from './public/images/google-play.png';
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";


const videoStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transform: "translate(-50%, -50%)",
    zIndex: "-1",
}

const HeroSection = ({ data }) => {
    const [isLoading, setIsLoading] = useState(true);
    const videoRef = useRef(null);

    useEffect(() => {
        // Handler for when the video can play through
        const handleCanPlayThrough = () => setIsLoading(false);

        // Adding event listener
        const video = videoRef.current;
        if (video) {
            video.addEventListener('canplaythrough', handleCanPlayThrough);
        }

        // Cleanup event listener on component unmount
        return () => {
            if (video) {
                video.removeEventListener('canplaythrough', handleCanPlayThrough);
            }
        };
    }, []);
    return (
        <Box sx={{ ...styles.heroBgContainer }}>
            {isLoading &&
                <CircularProgress color="inherit" />}
            <video autoPlay muted loop style={videoStyle} ref={videoRef}>
                <source src={BackgroundVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            {
                !isLoading &&
                <Box sx={styles.heroContentContainer}>

                    <Typography sx={styles.heroContentTitle}>
                        {data?.banners?.title}
                    </Typography>
                    <Typography sx={styles.heroContentSubtitle}>
                        {data?.banners?.content}
                    </Typography>
                    {/* <Button
                variant="contained"
                disableElevation
                color="error"
                sx={styles.contactButton}
                size="large"
            >
                Contact Us
            </Button>

            <Box sx={styles.orContentBreakWrapper}>
                <Box sx={styles.orContentBreak} />
                <Typography sx={styles.orTitle}> OR </Typography>
                <Box sx={styles.orContentBreak} />
            </Box>

            <Box sx={styles.appStoreContainer}>
                <Box sx={styles.appStoreWrapper}>
                    <img src={GooglePlayIcon} height="32" width="32" alt="Google Play" />
                    <Box>
                        <Typography sx={{ ...styles.appTexts, fontWeight: '400' }}>
                            Get it on
                        </Typography>
                        <Typography sx={{ ...styles.appTexts, fontWeight: '600' }}>
                            Google Play
                        </Typography>
                    </Box>
                </Box>
                <Box sx={styles.appStoreWrapper}>
                    <Icon icon="ion:logo-apple"
                        width="32"
                        height="32"
                        style={{ color: '#ffff' }} />
                    <Box>
                        <Typography sx={{ ...styles.appTexts, fontWeight: '400' }}>
                            Download on the
                        </Typography>
                        <Typography sx={{ ...styles.appTexts, fontWeight: '600' }}>
                            App Store
                        </Typography>
                    </Box>
                </Box>
            </Box> */}
                </Box>
            }
        </Box>
    )
}
HeroSection.propTypes = {
    data: PropTypes.shape(PropTypes.any)
}

export default HeroSection