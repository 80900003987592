import { Avatar, Badge, Box, Button, Collapse, Dialog, DialogContent, Divider, Drawer, Grid, List, ListItem, ListItemButton, ListItemText, Typography, useMediaQuery } from "@mui/material";
import { Icon } from '@iconify-icon/react';
import { useEffect, useRef, useState } from "react";
import * as styles from '../Styles/HomePage/navbar';
import { useLocation, useNavigate } from "react-router-dom";
import { logOut } from "../Services/authService";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetails } from "../Store/userSlice";
import CargoIcon from "../Assets/icons/cargo-icon.svg";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PropTypes from "prop-types";
import LogoIcon from "../Assets/icons/logoRed.svg"
const SideBarTextSize = {
    fontSize: "14px",
}

const HeaderImage = {
    fontFamily: "Open Sans",
    fontWeight: "bold",
    "img": {
        width: {
            xs: "120px",
            sm: "120px",
            md: "150px",
            lg: "150px",
            xl: "150px",
        },
        height: {
            xs: "50px",
            sm: "50px",
            md: "70px",
            lg: "70px",
            xl: "70px",
        },
    },
};
const Navbar = () => {
    const [dropdownOpen, setDropdownOpen] = useState({});
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();
    const location = useLocation();



    const handleMouseEnter = (menu) => {
        setDropdownOpen({ [menu]: true });
    };

    const handleMouseLeave = () => {
        setDropdownOpen({});
    };

    const handleItemClick = (menu) => {
        setDropdownOpen(prev => (prev[menu] ? {} : { [menu]: true }));
    };

    const [open, setOpen] = useState(false);

    const handleClick = (menu) => {
        if (open === menu) {
            setOpen();
        } else {
            setOpen(menu);
        }
    };

    const handleNavigate = (link) => {
        setIsNavMenuOpen(false)
        navigate(link)
        setOpen()
    }

    const getDropDown = (menu, service) => {
        if (menu === "Import") {
            if (service) {
                return [
                    {
                        title: 'DROP-OFF',
                        link: '/import/drop-off'
                    },
                    {
                        title: 'SERVICES',
                        link: '/import/services'
                    }
                ]
            }
            return [
                {
                    title: 'DROP-OFF',
                    link: '/import/drop-off'
                }
            ]
        } else if (menu === "Export") {
            if (service) {
                return [
                    {
                        title: 'PICK-UP',
                        link: '/export/pick-up'
                    },
                    {
                        title: 'SERVICES',
                        link: '/export/services'
                    }
                ]
            }
            return [
                {
                    title: 'PICK-UP',
                    link: '/export/pick-up'
                }
            ]
        }
    }

    const services = useSelector(state => state?.user?.services);
    const dropDownValues = {
        Import: getDropDown("Import", services?.import),
        Export: getDropDown("Export", services?.export),
        // Wallet: [
        //     {
        //         title: 'WALLET RECHARGE',
        //         link: '/wallet/wallet-recharge'
        //     },
        //     {
        //         title: 'WALLET TRANSACTION HISTORY',
        //         link: '/wallet/transaction-detail'
        //     }
        // ],
        Billing: [
            {
                title: 'BILLING/INOVICES',
                link: '/billing/invoices'
            },
            {
                title: 'TRANSACTION HISTORY',
                link: '/billing/transaction-detail'
            }
        ]
    }
    const mobileScreen = useMediaQuery('(max-width: 991px)');
    const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
    const navigate = useNavigate();
    const user = useSelector((state) => state?.user?.user)
    const DrawerList = (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", height: "100%" }} role="presentation">
            <Box sx={{ display: "flex", justifyContent: "space-between", padding: "8px 20px", background: "#fff" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                    <Avatar sx={{ bgcolor: "#FFF2EF", color: "#933118", fontSize: "14px" }}>
                        {user?.name ? user?.name?.split(" ").map(ele => ele[0]?.toUpperCase()) : ""}
                    </Avatar>
                    <Typography sx={{ margin: "auto" }}>
                        {user?.name ? user?.name : user?.email}
                    </Typography>
                </Box>
                {/* <Badge
                    // badgeContent={notificationsCount}
                    sx={{ '& .MuiBadge-badge': { backgroundColor: '#933118', color: '#ffffff' }, cursor: 'pointer' }}
                    // aria-controls={open ? 'basic-menu' : undefined}
                    // aria-haspopup="true"
                    // aria-expanded={open ? 'true' : undefined}
                    // onClick={handleClick}
                >
                    <img style={{ width: "20px", cursor: 'pointer' }} src={NotificationIcon} alt="" />
                </Badge> */}
            </Box>
            <Divider />
            <Box sx={{ flexGrow: "1" }}>
                <List sx={{ color: "#fff" }}>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => handleNavigate("/")} >
                            <ListItemText primary={"Home"}
                                primaryTypographyProps={{
                                    sx: {
                                        fontWeight: location?.pathname === "/" ? "600" : "400",
                                        textTransform: "uppercase",
                                        ...SideBarTextSize
                                    }
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                    {Object.keys(dropDownValues).map(menu => (
                        <>
                            <ListItem key={menu} sx={{ background: open === menu ? "#fff" : "transparent", color: open === menu ? "#000000" : "#fff" }} disablePadding onClick={() => handleClick(menu)}>
                                <ListItemButton>
                                    <ListItemText primary={menu} primaryTypographyProps={{ sx: { textTransform: "uppercase", ...SideBarTextSize } }} />
                                    {open === menu ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                            </ListItem>
                            <Collapse in={open === menu} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {dropDownValues[menu]?.map(item => (
                                        <ListItem key={item?.title} onClick={() => handleNavigate(item?.link)} >
                                            <ListItemButton
                                                sx={{ paddingTop: "0px", paddingBottom: "0px" }}
                                            >
                                                <ListItemText
                                                    primary={item?.title}
                                                    primaryTypographyProps={{
                                                        sx: { fontWeight: location?.pathname === item?.link ? "600" : "400", ...SideBarTextSize }
                                                    }}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        </>
                    ))}
                </List>
            </Box>
            <img style={{ width: "80%", cursor: 'pointer' }} src={CargoIcon} alt="" />
        </Box>
    );

    useEffect(() => {

        if (!mobileScreen) {
            setIsNavMenuOpen(false)
        }

    }, [mobileScreen])

    const [confirmLogoutModal, setConfirmLogoutModal] = useState(false)
    const handleOpenModal = () => {
        setConfirmLogoutModal(true)
    }

    const handleLogout = () => {
        dispatch(updateUserDetails(null));
        logOut();
    }

    return (
        <Box sx={styles.navbarContainer}>
            {mobileScreen &&
                <Icon icon="material-symbols:menu" width="32" height="32" onClick={() => setIsNavMenuOpen(true)} />}
            <Box sx={{ ...HeaderImage, textAlign: 'center', width: 'fit-content' }}>
                <img src={LogoIcon} alt="Container icon" />
                {/* <LogoIcon /> */}
                {/* <Typography sx={styles.brandTitle}>EMPEZAR</Typography>
                <Typography sx={styles.brandSubTitle}>GLOBAL</Typography> */}
            </Box>
            {
                !mobileScreen &&
                <Box sx={styles.navLinkWrapper}>
                    <Box sx={styles.navItemWrapper}>
                        <Typography sx={styles.navItem} onClick={() => navigate('/')}> Home </Typography>
                    </Box>
                    {Object.keys(dropDownValues).map(menu => (
                        <Box
                            key={menu}
                            onMouseEnter={() => handleMouseEnter(menu)}
                            onMouseLeave={handleMouseLeave}
                            sx={{ ...styles.navItemWrapper, position: 'relative' }} ref={dropdownRef}>
                            <Typography
                                onClick={() => handleItemClick(menu)}
                                sx={{ ...styles.navItem, color: dropdownOpen[menu] && '#933118' }}>
                                {menu}
                            </Typography>
                            {
                                dropdownOpen[menu] ?
                                    <Icon style={{ color: '#933118' }} icon="iconamoon:arrow-up-2-light" width="26" height="26" />
                                    :
                                    <Icon icon="iconamoon:arrow-down-2-light" width="26" height="26" />
                            }
                            {dropdownOpen[menu] && (
                                <Box sx={styles.navbarDropdownContainer}>
                                    {dropDownValues[menu].map((item, index) => (
                                        <>
                                            <Typography sx={{ ...styles.navbarDropdownItem, cursor: 'pointer' }} onClick={() => navigate(item.link)} key={item.title}>{item.title}</Typography>
                                            {index < dropDownValues[menu].length - 1 && <Divider sx={{ m: '1rem 0rem' }} />}
                                        </>
                                    ))}
                                </Box>
                            )}
                        </Box>
                    ))}
                    <Box sx={{ ...styles.navButtonsWrapper, gap: '1rem', mr: '2rem' }}>
                        <Box sx={{ ...styles.navButtonsWrapper, gap: '0.3rem' }}>
                            <Typography sx={styles.navItem}> {user?.name ? user?.name : user?.email} </Typography>
                            {/* <Icon
                                    icon="ph:user-circle-light"
                                    width="36px"
                                    height="36px"
                                /> */}
                        </Box>
                        <Button
                            variant="contained"
                            disableElevation
                            color="error"
                            sx={{
                                fontWeight: "medium",
                                textTransform: "none",
                                fontSize: {
                                    xs: "14px",
                                    sm: "14px",
                                    md: "14px",
                                    lg: "16px",
                                    xl: "18px",
                                },
                                background: "#933118 0% 0% no-repeat padding-box",
                                '&:hover': {
                                    background: "#933118 0% 0% no-repeat padding-box",
                                },
                                borderRadius: "30px",
                                padding: '0.3rem 1.5rem',
                            }}
                            size="large"
                            onClick={handleOpenModal}
                        >
                            Logout
                        </Button>
                    </Box>
                </Box>
            }
            {mobileScreen &&
                <Button
                    variant="contained"
                    disableElevation
                    color="error"
                    sx={{
                        ...styles.navLogoutButton,
                        padding: "2px 14px",
                        fontSize: "14px"
                    }}
                    size="large"
                    onClick={handleOpenModal}
                >
                    Logout
                </Button>}

            <Drawer
                open={isNavMenuOpen}
                onClose={() => setIsNavMenuOpen(false)}
                PaperProps={{
                    sx: {
                        borderRadius: "0 20px 20px 0px",
                        background: "linear-gradient(180deg, #933118, #270C05)",
                        width: {
                            xs: '80%',
                            sm: '40%',
                            md: '40%',
                            lg: '40%',
                            xl: '40%',
                        },
                    }
                }}
            >
                {DrawerList}
            </Drawer>

            {confirmLogoutModal && <LogoutConfirmation
                open={confirmLogoutModal}
                handleClose={() => setConfirmLogoutModal(false)}
                handleConfirmDelete={handleLogout}
            />}
        </Box>
    )
}

export default Navbar;

const TitleStyle = {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '28px',
    fontFamily: 'Poppins, sans-serif',
    letterSpacing: '0px',
    color: '#000000',
};
const ModalStyle = {
    backgroundColor: '#FFFFFF',
    backgroundPosition: '0% 0%',
    backgroundRepeat: 'no-repeat',
    padding: '10px',
    border: '1px solid #707070',
    borderRadius: '16px',
    width: '70%'
};

const SubHeadingStyle = {
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '28px',
    fontFamily: 'Poppins, sans-serif',
    letterSpacing: '0px',
    color: '#000000',
    marginTop: "1rem",
    marginBottom: "2rem",
    textTransform: 'capitalize',
    opacity: 1,
};
const CancelButtonStyle = {
    fontSize: '14px',
    backgroundColor: '#FFFFFF',
    backgroundPosition: '0% 0%',
    backgroundRepeat: 'no-repeat',
    padding: {
        xs: '12px 22px',
        sm: '12px 22px',
        md: '12px 22px',
        lg: '10px 30px',
        xl: '10px 30px'
    },
    textTransform: 'capitalize',
    border: '1px solid #933118',
    borderRadius: '4px',
    color: "#933118",
    opacity: 1,
    ":hover": {
        backgroundColor: '#FFFFFF',
        border: '1px solid #933118',
        color: "#933118",
    }
};
const DeleteButtonStyle = {
    fontSize: '14px',
    backgroundColor: '#933118',
    backgroundPosition: '0% 0%',
    backgroundRepeat: 'no-repeat',
    textTransform: 'capitalize',
    padding: {
        xs: '12px 22px',
        sm: '12px 22px',
        md: '12px 22px',
        lg: '10px 30px',
        xl: '10px 30px'
    },
    borderRadius: '4px',
    border: '1px solid #933118',
    opacity: 1,
    color: "#fff",
    ":hover": {
        backgroundColor: '#933118',
        border: '1px solid #933118',
        color: "#fff",
    }
};

const LogoutConfirmation = ({ open, handleClose, handleConfirmDelete }) => {
    const handleConfirm = () => {
        handleConfirmDelete()
    }

    return (
        <Dialog
            fullWidth
            maxWidth={'md'}
            open={open}
            PaperProps={{
                sx: { ...ModalStyle }
            }}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent sx={{ padding: "20px" }}>
                <Typography sx={TitleStyle}>Confirm the action</Typography>
                <Typography sx={{ ...SubHeadingStyle, marginTop: "1rem" }} textAlign="center">Are you sure you want to log out? Please confirm to proceed.</Typography>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item padding="0px" width="fit-content">
                        <Button sx={{ ...CancelButtonStyle }} onClick={() => handleClose()} variant="outlined">
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item padding="0px" width="fit-content" >
                        <Button sx={{ ...DeleteButtonStyle }} variant="outlined" type="button" onClick={handleConfirm} >
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

LogoutConfirmation.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleConfirmDelete: PropTypes.func,
}