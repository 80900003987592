import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import HeaderSection from '../../Components/HeaderSection';
import { formContainer, formTitle } from '../../Styles/Import/formSection';
import CustomSelectField from '../../Components/CustomSelectField';
import CustomDatePicker from '../../Components/CustomDateTimePicker';
import { useLazyGetWalletTransactionQuery } from '../../Services/AccountApi';
import TransactionDetailsTable from './TransactionDetailsTable';
import dayjs from 'dayjs';
import { ReactComponent as RefreshSvgIcon } from '../../Assets/icons/refresh-icon.svg';
import { RefreshIconButtonStyle } from '../../Styles/Common/style';

const StatusOptions = [
    {id:"failed" ,name:"Failed"},
    {id:"success" ,name:"Success"},
]

const ServiceOptions = [
    {id:"import" ,name:"Import"},
    {id:"export" ,name:"Export"},
]


const TransactionDetails = () => {
  const [getWalletTransactionApi,{ isLoading }] = useLazyGetWalletTransactionQuery()
  const [tableData,setTableData] = useState()
  const [filterParams, setFilterParams] = useState({service:"",status:"", page:1})
  const handleChange = (event) =>{
    const {name, value} = event.target;
    setFilterParams(prev=>({
        ...prev,
        [name]:value
    }))
  }
  const handleDateChange = (value) =>{
    setFilterParams(prev=>({
        ...prev,
        date:dayjs(value).format('DD-MM-YYYY')
    }))
  }

  useEffect(()=>{
      const handleFilterChange = async () =>{
        const {data, error} =  await getWalletTransactionApi(filterParams,false)
        if(data){
            setTableData(data)
        }
      }
      handleFilterChange()
  },[filterParams])

  const handleRefresh = () =>{
    setFilterParams({service:"",status:""})
  }

  const handlePageChange = async(page) =>{
    setFilterParams(prev=>({...prev,page:page}))
}

  return (
   <Box sx={{background: "#F5F5F5 0% 0% no-repeat padding-box"}}>
        <HeaderSection title="WALLET TRANSACTION HISTORY" />
        <Box sx={{...formContainer, margin:"auto"}} padding={"1rem 0 4rem 0"}>
            <Box display={'flex'} margin={"2rem 0px"}>
                <Typography flex={'1'} sx={formTitle}> Wallet Transaction Details </Typography>
                <Box sx={{...RefreshIconButtonStyle}} onClick={handleRefresh}>
                    <RefreshSvgIcon width={"100%"} height={"100%"} rotate={"90deg"} />
                </Box>
            </Box>
            <Grid container spacing={2} marginBottom={"1rem"} justifyContent="center">
                <Grid item xs={12} md={7} padding="0px" width="fit-content">
                    <Box width={{md:"30%",xs:"100%"}}>
                        <CustomSelectField
                            name={'service'}
                            placeholder={'select'}
                            value={filterParams?.service || ""}
                            label={'Select Service'}
                            onChange={handleChange}
                            options={ServiceOptions}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={3} padding="0px" width="fit-content" >
                    <CustomDatePicker
                        name={'schedule_date'}
                        placeholder={'select'}
                        value={filterParams?.date ? dayjs(filterParams?.date).format('DD-MM-YYYY') : null}
                        label={'Filter By Date'}
                        allDates={true}
                        onChange={handleDateChange}
                        // onChange={filterParams?.date}
                    />
                </Grid>
                <Grid item xs={12} md={2} padding="0px" width="fit-content" >
                    <CustomSelectField
                        name={'status'}
                        placeholder={'select'}
                        value={filterParams?.status|| ""}
                        label={'Search by Status'}
                        onChange={handleChange}
                        options={StatusOptions}
                    />
                </Grid>
            </Grid>
            {<TransactionDetailsTable tableData={tableData} handlePageChange={handlePageChange}/>}
        </Box>
    </Box>
  )
}

export default TransactionDetails