import { styled, Tooltip, tooltipClasses } from "@mui/material";

export const GrandTotalStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: {
    xs: "16px",
    sm: "16px",
    md: "16px",
    lg: "18px",
    xl: "24px",
  },
  lineHeight: "35px",
  fontWeight: 500, // 'medium' corresponds to 500
  letterSpacing: "0px",
  color: "#000000",
  textTransform: "capitalize",
  opacity: 1,
};

export const AmountTotalStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "14px",
    lg: "16px",
    xl: "18px",
  },
  lineHeight: "35px",
  fontWeight: 500, // 'medium' corresponds to 500
  letterSpacing: "0px",
  color: "#000000",
  textTransform: "capitalize",
  opacity: 1,
};

export const AmountSummaryStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "14px",
    lg: "16px",
    xl: "18px",
  },
  lineHeight: "26px",
  fontWeight: 500, // 'medium' corresponds to 500
};

export const ScrollBarStyle = {
  "&::-webkit-scrollbar": {
    width: "2px",
    height: "5px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#C3C3C3",
    borderRadius: "7px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "7px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
};

export const PaginationButton = {
  height: {
    xs: "28px",
    sm: "36px",
    md: "36px",
    lg: "36px",
    xl: "36px",
  },
  width: {
    xs: "28px",
    sm: "36px",
    md: "36px",
    lg: "36px",
    xl: "36px",
  },
  background: "#933118 0% 0% no-repeat padding-box",
  borderRadius: "4px",
  "&.Mui-disabled": {
    background: "#F2F2F2 0% 0% no-repeat padding-box",
    borderRadius: "4px",
  },
  ":hover": {
    background: "#933118 0% 0% no-repeat padding-box",
    borderRadius: "4px",
  },
};

export const PaginationPageNumber = {
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: "4px",
};

export const EditIconButtonStyle = {
  borderRadius: {
    xs: "2px",
    sm: "2px",
    md: "5px",
    lg: "5px",
    xl: "5px",
  },
  width: {
    xs: "25px",
    sm: "25px",
    md: "30px",
    lg: "30px",
    xl: "30px",
  },
  height: {
    xs: "25px",
    sm: "25px",
    md: "30px",
    lg: "30px",
    xl: "30px",
  },
  background: "#ECECEC",
  img: {
    width: {
      xs: "15.8px",
      sm: "15.8px",
      md: "22.8px",
      lg: "22.8px",
      xl: "22.8px",
    },
    height: {
      xs: "15px",
      sm: "15px",
      md: "22px",
      lg: "22px",
      xl: "22px",
    },
  },
  ":hover": { background: "#ECECEC" },
};
export const DeleteIconButtonStyle = {
  borderRadius: {
    xs: "2px",
    sm: "2px",
    md: "5px",
    lg: "5px",
    xl: "5px",
  },
  width: {
    xs: "25px",
    sm: "25px",
    md: "30px",
    lg: "30px",
    xl: "30px",
  },
  height: {
    xs: "25px",
    sm: "25px",
    md: "30px",
    lg: "30px",
    xl: "30px",
  },
  background: "#933118",
  img: {
    width: {
      xs: "10px",
      sm: "10px",
      md: "14px",
      lg: "14px",
      xl: "14px",
    },
    height: {
      xs: "14px",
      sm: "14px",
      md: "18px",
      lg: "18px",
      xl: "18px",
    },
  },
  ":hover": { background: "#933118" },
};

export const RefreshIconButtonStyle = {
  background: "#E9E9E9 0% 0% no-repeat padding-box",
  borderRadius: {
    xs: "2px",
    sm: "3px",
    md: "8px",
    lg: "8px",
    xl: "8px",
  },
  width: {
    xs: "18px",
    sm: "20px",
    md: "28px",
    lg: "48px",
    xl: "48px",
  },
  height: {
    xs: "18px",
    sm: "20px",
    md: "28px",
    lg: "48px",
    xl: "48px",
  },
  padding: {
    xs: "2px",
    sm: "2px",
    md: "5px",
    lg: "12px",
    xl: "12px",
  },
};
export const DownloadBoldIconButtonStyle = {
  background: "#933118 0% 0% no-repeat padding-box",
  borderRadius: "4px",
  ":hover": {
    background: "#933118 0% 0% no-repeat padding-box",
    borderRadius: "4px",
  },
  display: "flex",
  alignItems: "center",
  width: "30px",
  height: "30px",
  cursor: "pointer",
  justifyContent: "center",
};
export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));