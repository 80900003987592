import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from "@mui/material";
import * as styles from "../Styles/componentStyles/ServicesSection";
import { useLazyGetServicesContentQuery } from '../Services/UserApi';

const ImportMethodSections = [
    {
        heading: "Import Method Services",
        items: [
            "Advance collection of LOLO",
            "Reminder of advance LOLO collection when 30% amount remains"
        ]
    },
    {
        heading: "Terms & Conditions",
        items: [
            "Advance collection of LOLO",
            "Reminder of advance LOLO collection when 30% amount remains"
        ]
    }
];

const ExportMethodSections = [
    {
        heading: "Export-Services",
        items: [
            "Advance collection of LOLO",
            "Visibility of container type, size, and grade",
            "Provision of appointing or blocking the container lot for pickup",
            "Reminder of advance LOLO collection when 30% amount remains"
        ]
    },
    {
        heading: "Terms & Conditions",
        items: [
            "Advance collection of LOLO",
            "Reminder of advance LOLO collection when 30% amount remains"
        ]
    }
];

const ServicesSection = ({ sectionType }) => {
    const [getContentApi] = useLazyGetServicesContentQuery()
    const [content, setContent] = useState()
    useEffect(() => {
        const getData = async () => {
            const { data } = await getContentApi({ operation_type: sectionType }, false)
            if (data) {
                setContent(data)
            }
        }
        getData()
    }, [])
    return (
        <Box sx={styles.ServicesDescContainer}>
            {content?.data?.content && <div dangerouslySetInnerHTML={{ __html: content?.data?.content }} />}
            {!content?.data?.content && <Box> No Content </Box>}
        </Box>
    )
}

ServicesSection.propTypes = {
    sectionType: PropTypes.string.isRequired
}

export default ServicesSection