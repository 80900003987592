import { createApi } from "@reduxjs/toolkit/query/react";
import AuthenticatedBaseQuery from "./AuthenticatedQuery";

export const importApi = createApi({
  reducerPath: "importApi",
  baseQuery: AuthenticatedBaseQuery,
  endpoints: (builder) => ({
    getInitialFormData: builder.mutation({
      query: () => ({
        url: `/import/retrieve-import-data/`,
        method: "GET",
      }),
    }),

    getShippingLinesData: builder.mutation({
      query: (params) => ({
        url: `/import/shipping-lines/by_yard/`,
        method: "GET",
        params,
      }),
    }),

    getContainerSizeTypesData: builder.mutation({
      query: (params) => ({
        url: `/import/container_size_types/by_yard_shipping_line/`,
        method: "GET",
        params,
      }),
    }),

    fetchScheduledDates: builder.query({
      query: ({ yard_id }) => ({
        url: `/import/schedule_dates/by_yard/`,
        method: "GET",
        params: {
          yard_id,
        },
      }),
    }),

    fetchTimeSlots: builder.mutation({
      query: ({ schedule_date }) => ({
        url: `/import/time_slots/by_date/`,
        method: "GET",
        params: {
          schedule_date,
        },
      }),
    }),

    fetchContainerNumbers: builder.mutation({
      query: ({ container_size_type_id, yard_id }) => ({
        url: `/import/retrieve-container-numbers/`,
        method: "GET",
        params: {
          container_size_type_id: container_size_type_id,
          yard_id: yard_id,
        },
      }),
    }),

    getSheduledDates: builder.query({
      query: ({ yard_id }) => ({
        url: `/import/schedule_dates/by_yard/`,
        method: "GET",
        params: {
          yard_id,
        },
      }),
    }),
    getTimeSlots: builder.query({
      query: ({ schedule_date }) => ({
        url: `/import/time_slots/by_date/`,
        method: "GET",
        params: {
          schedule_date,
        },
      }),
    }),
    getContainerNumbers: builder.query({
      query: ({ container_size_type_id }) => ({
        url: `/import/retrieve-container-numbers/`,
        method: "GET",
        params: {
          container_size_type_id,
        },
      }),
    }),
    addImportContainerDetails: builder.mutation({
      query: (body) => ({
        url: `/import/drop-off/`,
        method: "POST",
        body,
      }),
    }),

    getDrofOffDetails: builder.mutation({
      query: ({ id }) => ({
        url: `/import/drop-off/${id}/`,
        method: "GET",
      }),
    }),

    fetchImportContainerDetails: builder.mutation({
      query: (sortType) => ({
        url: `/import/drop-off/`,
        method: "GET",
        params: {
          sort: sortType,
        },
      }),
    }),
    updateDropOff: builder.mutation({
      query: (body) => ({
        url: `/import/drop-off-detail/${body?.get("id")}/`,
        method: "PATCH",
        body,
      }),
    }),
    removeImportContainerDetails: builder.mutation({
      query: (id) => ({
        url: `/import/drop-off-detail/${id}/`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetInitialFormDataMutation,
  useGetContainerSizeTypesDataMutation,
  useGetShippingLinesDataMutation,

  useLazyGetSheduledDatesQuery,
  useLazyGetTimeSlotsQuery,
  useLazyGetContainerNumbersQuery,

  useAddImportContainerDetailsMutation,

  useLazyFetchScheduledDatesQuery,
  useFetchTimeSlotsMutation,
  useFetchContainerNumbersMutation,

  useGetDrofOffDetailsMutation,
  useFetchImportContainerDetailsMutation,
  useUpdateDropOffMutation,
  useRemoveImportContainerDetailsMutation,
} = importApi;
