import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BookButton, HeadingStyle, TableCellStyle, TableHeader } from '../../Styles/Form/Field';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from 'react-router-dom';
import { useCreateExportOrderApiMutation } from '../../Services/ExportPaymentApi';
import { enqueueSnackbar } from 'notistack';
import TotalAmountSummary from './TotalAmountSummary';
import dayjs from 'dayjs';
import { formContainer } from '../../Styles/Import/formSection';
import { PaginationButton, PaginationPageNumber, ScrollBarStyle } from '../../Styles/Common/style';
import EditButtonIcon from '../../Assets/icons/edit-icon.svg';
import DeleteButtonIcon from '../../Assets/icons/delete-icon.svg';
import DeleteConfirmation from '../../Components/DeleteConfirmation';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddNewTransporterModal from '../../Components/AddNewTransporterModal';

const ExportPickUpTable = ({ containerData, handleEdit, handleAddPickUp, handleDelete, summary, handlePageChange, handleSortChange, sortType }) => {
  const [createOrderApi, { isLoading: createOrderApiLoading }] = useCreateExportOrderApiMutation();
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState("")
  const navigate = useNavigate();
  const [billToModal, setBillToModal] = useState(false);

  const handlePrev = () => {
    if (containerData?.previous) {
      handlePageChange(containerData?.previous)
    }
  };
  const handleNext = () => {
    if (containerData?.next) {
      handlePageChange(containerData?.next)
    }
  };

  const handleSubmit = async () => {
    setBillToModal(true)
    // const { data, error } = await createOrderApi({ operation_type: "export", pickups: containerData?.data?.map(ele => ele.id) })
    // if (data) {
    //   navigate('/billing/billing', { state: { mode: "export", ...summary } })
    // }
    // if (error) {
    //   enqueueSnackbar('Something went wrong !', {
    //     variant: 'error',
    //     anchorOrigin: { vertical: 'top', horizontal: 'center' },
    //   });
    //   console.log(error)
    // }
  }

  const openDeleteModal = (id) => {
    setDeleteId(id);
    setConfirmDeleteModal(true)
  }

  const closeDeleteModal = () => {
    setDeleteId("");
    setConfirmDeleteModal(false)
  }

  const handleDeletePickUp = () => {
    handleDelete(deleteId)
    setDeleteId("");
    setConfirmDeleteModal(false)

  }
  const handleSaveBillGenrationDetails = async (values) => {
    setBillToModal(false)
    const formData = new FormData()
    values && Object.keys(values)?.forEach(key => {
      formData.append(key, values[key])
    })
    formData.append('operation_type', "import")
    containerData?.data?.forEach(ele => {
      formData.append('pickups', ele.id)
    })
    const { data, error } = await createOrderApi(formData)
    if (data) {
      navigate('/billing/billing', { state: { mode: "export", ...summary } })
    }
    if (error) {
      enqueueSnackbar(error?.data?.message || 'Something went wrong !', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
      console.log(error)
    }
  }

  return (
    <Box sx={{ background: '#F5F5F5 0% 0% no-repeat padding-box' }}>
      <Typography sx={{ ...HeadingStyle }}>
        Export Pick-Up Details Form
      </Typography>
      <Box sx={{ ...formContainer, margin: "auto" }}>
        <Typography sx={{
          fontWeight: 500, mt: '0.5rem', marginBottom: "0.5rem", fontSize: {
            xs: '14px',
            sm: '14px',
            md: '14px',
            lg: '16px',
            xl: '18px',
          },
        }}>Sort by</Typography>
        <FormControl>
          <Select
            IconComponent={KeyboardArrowDownIcon}
            size='small'
            sx={{
              '& .MuiSelect-select': {
                fontSize: {
                  xs: '14px',
                  sm: '14px',
                  md: '14px',
                  lg: '16px',
                  xl: '18px',
                },
              },
              minWidth: '250px',
              mb: '2rem',
              backgroundColor: '#fff',
              color: 'grey'
            }}
            id="demo-simple-select"
            value={sortType}
            onChange={handleSortChange}
          >
            <MenuItem value={'asc'}>Ascending</MenuItem>
            <MenuItem value={'desc'}>Descending</MenuItem>
          </Select>
        </FormControl>
        <TableContainer sx={{ borderRadius: '8px 8px 0 0', ...ScrollBarStyle }}>
          <Table
            sx={{ minWidth: 650, borderRadius: '8px', textWrap: 'nowrap' }}
            aria-label="simple table"
          >
            <TableHead
              sx={{ background: '#ECECEC', borderRadius: '8px 8px 0 0' }}
            >
              <TableRow sx={{ borderRadius: '8px' }}>
                <TableCell sx={TableHeader} align="center">
                  Sr.No
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Date
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Container Number
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Yard
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Shipping Line
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Booking Number
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Validity
                </TableCell><TableCell sx={TableHeader} align="center">
                  Size Type
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Cargo Type
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Amount
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  GST
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Total
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  {' '}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ background: "#fff" }}>
              {containerData?.data?.map((row, index) => (
                <TableRow
                  key={(containerData?.current_page - 1) * containerData?.per_page + index + 1}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={TableCellStyle} align="center">
                    {(containerData?.current_page - 1) * containerData?.per_page + index + 1}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    {dayjs(row?.schedule_date?.date).format('DD-MM-YYYY')}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    {row?.containers?.map(ele => ele?.container_number)?.join(", ")}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    {row?.yard?.name}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    {row?.shipping_line?.name}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    {row?.bn_number}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    {dayjs(row?.validity).format('DD-MM-YYYY')}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    {row?.container_size_type?.container_size_type}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    {row?.cargo_type?.name}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    {row?.amount?.toFixed(2)} ₹
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    {row?.gst}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    {row?.total_amount?.toFixed(2)} ₹
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    <IconButton sx={{ borderRadius: "5px", width: "30px", height: "30px", background: "#ECECEC", ':hover': { background: "#ECECEC", } }} color='success' onClick={() => handleEdit(row)}>
                      <img src={EditButtonIcon} alt="" />
                    </IconButton>{' '}
                    <IconButton sx={{ borderRadius: "5px", width: "30px", height: "30px", background: "#933118", ':hover': { background: "#933118", } }} color='error' onClick={() => openDeleteModal(row?.id)}>
                      <img src={DeleteButtonIcon} alt="" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {containerData?.data ?
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '12px',
              background: "#fff",
              borderRadius: '8px',
              padding: '1rem 0rem',
            }}
          >
            <IconButton
              sx={{
                ...PaginationButton
              }}
              disabled={containerData?.previous === null}
              onClick={handlePrev}>
              <KeyboardArrowLeftIcon sx={{ fill: containerData?.previous === null ? "#000000" : "#FFFFFF" }} />
            </IconButton>
            <Paper sx={{ padding: '0px 12px', borderRadius: '8px', ...PaginationPageNumber }}>
              <Typography>{containerData?.current_page}</Typography>
            </Paper>

            <IconButton
              variant="contained"
              onClick={handleNext}
              sx={{
                ...PaginationButton
              }}
              disabled={containerData?.next === null}
            >
              <KeyboardArrowRightIcon sx={{ fill: containerData?.next === null ? "#000000" : "#FFFFFF" }} />
            </IconButton>
          </Box> :
          <Typography sx={{
            minHeight: '5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 600
          }}>No Data Found</Typography>
        }
        {summary && <TotalAmountSummary summary={summary} />}
        {confirmDeleteModal && <DeleteConfirmation dataTitle="Pick-Up" handleClose={closeDeleteModal} open={confirmDeleteModal} handleConfirmDelete={handleDeletePickUp} />}
        <Grid container spacing={2} sx={{ mt: '1.2rem' }}>
          <Grid item xs={12} sm={6}>
            <Button sx={{ ...BookButton }} onClick={() => handleAddPickUp()} variant="contained">
              Add Pick Up
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginBottom: '5rem' }}>
            <Button sx={{ ...BookButton }} variant="contained" type="button" onClick={handleSubmit}
              disabled={!containerData?.data || containerData?.data?.length === 0}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
      {billToModal &&
        <AddNewTransporterModal
          defaultValue={containerData?.third_party_user_data}
          apiLoading={createOrderApiLoading}
          open={billToModal}
          closeModal={() => setBillToModal(false)}
          handleSaveBillGenrationDetails={handleSaveBillGenrationDetails}
        />
      }
    </Box>
  );
};
ExportPickUpTable.propTypes = {
  containerData: PropTypes.shape(PropTypes.any),
  summary: PropTypes.shape(PropTypes.any),
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  handleAddPickUp: PropTypes.func,
  handlePageChange: PropTypes.func,
  sortType: PropTypes.string,
  handleSortChange: PropTypes.func
};

export default ExportPickUpTable;
