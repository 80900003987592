import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import dayjs from 'dayjs';
import { PaginationButton, PaginationPageNumber, ScrollBarStyle } from '../../Styles/Common/style';
import PropTypes from 'prop-types';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { TableCellStyle, TableHeader } from '../../Styles/Form/Field';

const TransactionDetailsTable = ({tableData, handlePageChange}) => {
    const handlePrev = () => {
        if(tableData?.previous){
            handlePageChange(tableData?.previous)
        }
    };
    const handleNext = () => {
        if(tableData?.next){
            handlePageChange(tableData?.next)
        }
    };
  return (
    <>
        <TableContainer sx={{ borderRadius: '8px 8px 0 0',...ScrollBarStyle }}>
            <Table
                sx={{ minWidth: 650, borderRadius: '8px', textWrap: 'nowrap' }}
                aria-label="simple table"
            >
                <TableHead
                sx={{ background: '#ECECEC', borderRadius: '8px 8px 0 0' }}
                >
                <TableRow sx={{ borderRadius: '8px' }}>
                    <TableCell sx={TableHeader} align="center">
                        Sr.No
                    </TableCell>
                    <TableCell sx={TableHeader} align="center">
                        Date
                    </TableCell>
                    <TableCell sx={TableHeader} align="center">
                        Transaction Id
                    </TableCell>
                    <TableCell sx={TableHeader} align="center">
                        Amount
                    </TableCell>
                    <TableCell sx={TableHeader} align="center">
                        Status
                    </TableCell>
                </TableRow>
                </TableHead>
                <TableBody sx={{ background:"#fff"}}>
                {tableData?.data?.map((row,index) => (
                    <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell sx={TableCellStyle} align="center">
                        {index+1}
                    </TableCell>
                    <TableCell sx={TableCellStyle} align="center">
                        {row?.created_at}
                    </TableCell>
                    <TableCell sx={TableCellStyle} align="center">
                        {row?.converted_transaction_id}
                    </TableCell>
                    <TableCell sx={TableCellStyle} align="center">
                        {row?.amount}
                    </TableCell>
                    <TableCell sx={{ ...TableCellStyle, textTransform:"capitalize",color: row?.status==="success"? "#0D7B26" :"#C2191F" }} align="center">
                        {row?.status}
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
        {tableData?.data?.length > 0 ?
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '12px',
                    background:"#fff",
                    borderRadius: '8px',
                    padding: '1rem 0rem',
                }}
                >
               <IconButton sx={{
              ...PaginationButton
            }} disabled={tableData?.previous === null} onClick={handlePrev}>
            <KeyboardArrowLeftIcon sx={{fill:tableData?.previous === null?"#000000":"#FFFFFF"}}/>
            </IconButton>
            <Paper sx={{ padding: '0px 12px', borderRadius: '8px', ...PaginationPageNumber }}>
            <Typography>{1}</Typography>
            </Paper>

            <IconButton
            variant="contained"
            onClick={handleNext}
            disabled={tableData?.next === null}
            sx={{
                ...PaginationButton
            }}
            >
            <KeyboardArrowRightIcon sx={{fill:tableData?.next === null?"#000000":"#FFFFFF"}} />
            </IconButton>
                </Box>:
                <Typography sx={{
                minHeight: '5rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 600
            }}>No Data Found</Typography>
                }
    </>
  )
}
TransactionDetailsTable.propTypes ={
    tableData : PropTypes.shape(PropTypes.any),
    handlePageChange: PropTypes.func
}

export default TransactionDetailsTable;
