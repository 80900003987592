import React, { useEffect } from 'react'
import HeroSection from './HeroSection'
import AboutSection from './AboutSection'
import { useGetHomePageMutation, useGetUserDetailsMutation } from '../../Services/UserApi';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserDetails } from '../../Store/userSlice';
import { Helmet } from 'react-helmet-async';

const HomePage = () => {
  const user = useSelector(state => state?.user);
  const [userDetailApi] = useGetUserDetailsMutation();
  const dispatch = useDispatch();

  const [getHomePageContentApi, { data }] = useGetHomePageMutation()
  useEffect(() => {
    if (user?.tokens?.refresh) {
      getHomePageContentApi()
    }
  }, [user, getHomePageContentApi])
  useEffect(() => {
    const getData = async () => {
      const { data, error } = await userDetailApi(null)
      console.log(error)
      if (data) {
        dispatch(updateUserDetails(data?.data));
      }
    }
    if (user?.tokens?.refresh) {
      getData()
    }
  }, [user?.tokens?.refresh, userDetailApi, dispatch])
  return (
    <div>
      <Helmet>
        <title>Home | Gati se Pragati</title>
      </Helmet>
      <HeroSection data={data?.data} />
      <AboutSection data={data?.data} />
    </div>
  )
}

export default HomePage