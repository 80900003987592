import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import CustomSelectField from '../../Components/CustomSelectField';
import CustomUploadField from '../../Components/CustomUploadField';
import {
  BackButton,
  BookButton,
  FormInputField,
  FormLabelStyle,
} from '../../Styles/Form/Field';
import {
  useCreatePickupMutation,
  useExportFormOptionsMutation,
  useContainerNumberListMutation,
  useGetExportShippingLinesDataMutation,
  useGetExportContainerSizeTypeDataMutation
} from '../../Services/ExportApi';
import CustomDatePicker from '../../Components/CustomDateTimePicker';
import { exportFormSchema } from '../../Utils/ValidationSchemas';
import ExportContainerTable from './ExportContainerTable';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { formContainer, formTitle } from '../../Styles/Import/formSection';
import { ReactComponent as RefreshSvgIcon } from '../../Assets/icons/refresh-icon.svg';
import { RefreshIconButtonStyle } from '../../Styles/Common/style';


const ExportPickUpForm = ({ handleBack, disableYard, defaultYard }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formOtionsApi] = useExportFormOptionsMutation();
  const [fetchShippingLinesData, { isLoading: fetchingShippingLines, data: shippingLinesData }] = useGetExportShippingLinesDataMutation()
  const [fetchContainerSizeTypes, { isLoading: fetchingContainerSizeTypes, data: containerSizeTypes }] = useGetExportContainerSizeTypeDataMutation()
  const [containerListApi, { isLoading: ContainerListApiLoading, }] =
    useContainerNumberListMutation();
  const [createPickupApi, { isLoading: createPickupApiLoading }] = useCreatePickupMutation();
  const [containerList, setContainerList] = useState();
  const [pickUpFormData, setPickUpFormData] = useState({});
  const [formOtions, setFormOtions] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const { data, error } = await formOtionsApi(null);
      if (data) {
        setFormOtions(data?.data);
      }
      if (error) {
        enqueueSnackbar(error?.message || "Something went wrong !", {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
        console.log(error)
      }
    };
    getData();
  }, [formOtionsApi, enqueueSnackbar]);




  const formik = useFormik({
    initialValues: {
      yard: defaultYard || '',
      shipping_line: '',
      schedule_date: '',
      bn_number: '',
      validity: '',
      container_size_type: '',
      cargo_type: '',
      bn_image: '',
    },
    validationSchema: exportFormSchema,
    onSubmit: (values, { resetForm, setErrors }) => {
      handleFormSubmit(values, resetForm, setErrors);
    },
  });

  useEffect(() => {
    if (formik?.values?.yard) {
      formik.setFieldValue('shipping_line', "")
      formik.setFieldValue('container_size_type', "")
      fetchShippingLinesData({ yard_id: formik?.values?.yard });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.yard])


  useEffect(() => {
    if (formik?.values?.yard && formik?.values?.shipping_line) {
      formik.setFieldValue('container_size_type', "")
      fetchContainerSizeTypes({ yard_id: formik?.values?.yard, shipping_line_id: formik?.values?.shipping_line });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.yard, formik?.values?.shipping_line])

  const handleShippingLineChange = async (event) => {
    formik.setFieldValue('container_size_type', "")
    formik.handleChange(event);
  };
  const handleScheduleDateChange = async (value, id) => {
    formik.setFieldValue('validity', '')
    formik.setFieldValue('schedule_date', value);
  };

  const getContainerSizeType = (containerSizeTypes, id) => {
    return containerSizeTypes.find((obj) => obj.id === id)?.id;
  };

  const fetchContainerList = async (containerSizeType, yard_id) => {
    try {
      const { data, error } = await containerListApi({ container_size_type_id: containerSizeType, yard_id: yard_id });
      if (data) {
        return { data: data?.data || [], error: null };
      }
      return { data: [], error };
    } catch (error) {
      console.error('Error fetching container list:', error);
      return { data: [], error };
    }
  };

  const handleFormSubmit = async (values, resetForm, setErrors) => {
    const containerSizeType = getContainerSizeType(containerSizeTypes?.data?.container_size_types || [], formik.values.container_size_type);

    if (!containerSizeType) return;

    const { data, error } = await fetchContainerList(containerSizeType, values?.yard);
    if (data?.length > 0) {
      setPickUpFormData(values);
      setContainerList(data);
    } else {
      enqueueSnackbar(error?.data?.message || 'No Containers Data Found!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
      setContainerList();
    }
  };

  const getErrorMessage = (touched, error) => touched ? error : '';

  const handleContainerSelection = async (selectedContainerNumbers) => {
    if (selectedContainerNumbers?.length > 0) {
      const formData = new FormData();
      Object.entries(pickUpFormData).forEach(([key, value]) =>
        formData.append(key, value)
      );
      formData.delete('schedule_date')
      formData.append('schedule_date', pickUpFormData?.schedule_date)
      selectedContainerNumbers.forEach(ele => {
        formData.append('container_ids', ele);
      })
      formData.append('status', 'scheduled')
      formData.append('payment_status', 'pending')
      const { data, error } = await createPickupApi(formData);
      if (data) {
        enqueueSnackbar('Pickup Created Succesfully !', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
        setContainerList();
        formik.resetForm();
        handleBack()
      }
      if (error) {
        enqueueSnackbar(error?.data?.message || 'Something went wrong!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
        console.log(error);
      }
    } else {
      enqueueSnackbar('Select atleast one container!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
    }
  };
  const handleRefresh = () => {
    formik.resetForm();
    setContainerList();
  }
  return (
    <>
      <Box sx={formContainer}>
        <Box display={'flex'} margin={"2rem 0px"}>
          <Typography flex={'1'} sx={formTitle}>  Export Pick-Up Details Form </Typography>
          <Box sx={{ ...RefreshIconButtonStyle }} onClick={handleRefresh}>
            <RefreshSvgIcon width={"100%"} height={"100%"} rotate={"90deg"} />
          </Box>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <CustomSelectField
                name={'yard'}
                required={true}
                placeholder={'select'}
                value={formik.values.yard}
                label={'Yard'}
                onChange={formik.handleChange}
                options={formOtions?.yards || []}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.yard &&
                  Boolean(formik?.errors?.yard)
                }
                helperText={getErrorMessage(formik.touched.yard, formik.errors.yard)}
                optionError={formOtions?.yards?.length === 0 && 'No Yards Available'}
                disable={formOtions?.yards?.length === 0 || disableYard}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomDatePicker
                required={true}
                name={'schedule_date'}
                placeholder={'select'}
                value={formik.values.schedule_date}
                label={'Pick Schedule Date'}
                onChange={handleScheduleDateChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.schedule_date &&
                  Boolean(formik.errors.schedule_date)
                }
                helperText={getErrorMessage(formik.touched.schedule_date, formik.errors.schedule_date)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomSelectField
                required={true}
                name={'shipping_line'}
                placeholder={'select'}
                value={formik.values.shipping_line}
                label={'Shipping Line'}
                onChange={handleShippingLineChange}
                options={shippingLinesData?.data?.shipping_lines || []}
                disable={!formik?.values?.yard || fetchingShippingLines || shippingLinesData?.data?.shipping_lines?.length === 0}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.shipping_line &&
                  Boolean(formik.errors.shipping_line)
                }
                helperText={
                  formik.touched.shipping_line
                    ? formik.errors.shipping_line
                    : ''
                }
                optionError={(formik?.values?.yard && shippingLinesData?.data?.shipping_lines?.length === 0) && 'No Shipping Lines Available for Selected Yard'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ ...FormLabelStyle }}>Booking Number <span style={{ color: "red" }}>*</span></Typography>
              <TextField
                variant="outlined"
                fullWidth
                // type="number"
                sx={{
                  ...FormInputField,
                }}
                name={'bn_number'}
                placeholder={'Enter Booking Number'}
                value={formik.values.bn_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.bn_number && Boolean(formik.errors.bn_number)
                }
              />
              <FormHelperText sx={{ marginLeft: '14px' }} error>
                {formik.touched.bn_number ? formik.errors.bn_number : ''}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomDatePicker
                disable={!formik?.values.schedule_date}
                selectToday={formik?.values.schedule_date}
                name={'validity'}
                required={true}
                placeholder={'select'}
                value={formik.values.validity}
                label={'Validity'}
                onChange={(value) => formik.setFieldValue('validity', value)}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.validity && Boolean(formik.errors.validity)
                }
                helperText={
                  formik.touched.validity ? formik.errors.validity : ''
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomSelectField
                required={true}
                name={'container_size_type'}
                placeholder={'select'}
                value={formik.values.container_size_type}
                label={'Container Size Type'}
                onChange={formik.handleChange}
                options={
                  containerSizeTypes?.data?.container_size_types?.map((item) => ({
                    id: item.id,
                    name: item.container_size_type,
                  })) || []
                }
                disable={fetchingContainerSizeTypes || !formik?.values.yard || !formik?.values.shipping_line || containerSizeTypes?.data?.container_size_types?.length === 0}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.container_size_type &&
                  Boolean(formik.errors.container_size_type)
                }
                helperText={
                  formik.touched.container_size_type
                    ? formik.errors.container_size_type
                    : ''
                }
                optionError={
                  (formik?.values.yard && formik?.values.shipping_line && containerSizeTypes?.data?.container_size_types?.length === 0) &&
                  'No Container Size Type avaiable for Selected Yard & Shipping Line'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomSelectField
                required={true}
                name={'cargo_type'}
                placeholder={'select'}
                value={formik.values.cargo_type}
                label={'Cargo Type'}
                onChange={formik.handleChange}
                options={formOtions?.cargo_types || []}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.cargo_type && Boolean(formik.errors.cargo_type)
                }
                helperText={
                  formik.touched.cargo_type ? formik.errors.cargo_type : ''
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <CustomUploadField
                name="bn_image"
                required={true}
                label="Attach Booking Number"
                onChange={(file) => {
                  formik.setFieldTouched('bn_image', true)
                  formik.setFieldValue('bn_image', file)
                }
                }
                error={
                  formik.touched.bn_image && Boolean(formik.errors.bn_image)
                }
                helperText={
                  formik.touched.bn_image ? formik.errors.bn_image : ''
                }
                value={formik.values.bn_image}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button sx={{ ...BackButton }} onClick={() => handleBack()} variant="outlined">
                Back
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button sx={{ ...BookButton }} variant="contained" type="submit">
                Book
              </Button>
            </Grid>
          </Grid>
        </form>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={createPickupApiLoading || ContainerListApiLoading || createPickupApiLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
      {containerList && <ExportContainerTable
        containerData={containerList}
        formData={pickUpFormData}
        formOptions={formOtions}
        shippingLines={shippingLinesData?.data?.shipping_lines || []}
        containerSizeTypes={containerSizeTypes?.data?.container_size_types}
        handleClick={handleContainerSelection} />}
    </>
  )
}

ExportPickUpForm.propTypes = {
  handleBack: PropTypes.func.isRequired,
  disableYard: PropTypes.bool,
  defaultYard: PropTypes.string
}

export default ExportPickUpForm;
