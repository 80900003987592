import { Dialog, DialogContent } from '@mui/material'
import React from 'react'
import PropTypes from "prop-types";
const ViewInvoice = ({ open, handleClose, htmlData }) => {
  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={open}
      PaperProps={{
      }}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <iframe
          srcDoc={htmlData}
          style={{ width: '100%', height: '600px', border: 'none', overflow: 'hidden', overflowY: "hidden" }}
          title="HTML Content"
        />
      </DialogContent>
    </Dialog>
  )
}

ViewInvoice.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  htmlData: PropTypes.string,
}
export default ViewInvoice