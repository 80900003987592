import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const formatTime = (time) => {
    if (!time) return ''; // Check if time is undefined
    const [hours, minutes] = time.split(':');
    const hoursNum = parseInt(hours);
    const period = hoursNum >= 12 ? 'PM' : 'AM';
    const formattedHours = hoursNum % 12 === 0 ? 12 : hoursNum % 12;
    return `${formattedHours}:${minutes}${period}`;
};
const menuProps = {
    PaperProps: {
        style: {
            maxHeight: 200, // Adjust this value to change the height of the options list
        },
    },
};
const BasicSelectBox = ({ id, name, options, label, onChange, onBlur, fieldValue, fieldTouched, fieldError, isLoading, disabled, showRemoveButton, handleRemoveContainer, optionError }) => {

    const transformSchedules = (schedules) => {
        if (schedules && (name === 'yard' || name === 'shipping_line')) {
            return schedules?.reduce((acc, { id, name }) => {
                acc.push({ id, value: name });
                return acc;
            }, []);
        }

        else if (schedules && name.includes('container_size_type')) {
            return schedules?.reduce((acc, { id, container_size_type }) => {
                acc.push({ id, value: container_size_type });
                return acc;
            }, []);
        }

        else if (schedules && name === 'time_slot') {
            return schedules?.reduce((acc, { id, start_time, end_time }) => {
                acc.push({ id, value: `${formatTime(start_time)} - ${formatTime(end_time)}` });
                return acc;
            }, []);
        }

        else if (schedules && name?.includes('container_number')) {
            if (schedules.error) return [];
            return schedules?.map(({ id, container_number }) => ({ id: container_number, value: container_number }));
        }
        return []
    };

    const transformedSchedules = transformSchedules(options);

    return (
        <FormControl fullWidth>
            {showRemoveButton &&
                <Typography>
                    <RemoveCircleOutlineIcon
                        sx={{ float: "right", color: "red", cursor: "pointer", width: "18px", marginTop: "-23px" }}
                        onClick={handleRemoveContainer} />
                </Typography>
            }
            <InputLabel id="demo-simple-select-label" InputLabelProps={{ shrink: true }}>{label}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id={id}
                MenuProps={menuProps}
                // value={age}
                disabled={disabled}
                label={label}
                name={name}
                IconComponent={KeyboardArrowDownIcon}
                // size='small'
                onChange={onChange}
                onBlur={onBlur}
                value={fieldValue}
                sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: () => `${(fieldTouched && fieldError) && "1px solid red"}`
                    },
                }}
            >
                <MenuItem value="">Select</MenuItem>
                {transformedSchedules?.map((schedule) => (
                    <MenuItem key={schedule.id} value={schedule.id}>{schedule.value}</MenuItem>
                ))}
            </Select>
            <FormHelperText>
                {optionError &&
                    <span style={{ fontSize: '12px', color: 'darkred' }}>({optionError})</span>
                }
            </FormHelperText>
        </FormControl>
    )
}

BasicSelectBox.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    fieldValue: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    handleRemoveContainer: PropTypes.func,
    fieldTouched: PropTypes.bool,
    fieldError: PropTypes.string,
    options: PropTypes.array,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    showRemoveButton: PropTypes.bool,
    optionError: PropTypes.string
}

export default BasicSelectBox