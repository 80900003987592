import {
  Button,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BookButton, HeadingStyle, TableCellStyle, TableHeader } from '../../Styles/Form/Field';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from "../../Assets/icons/closeicon.svg"
const ExportContainerModalTable = ({
  containerData,
  open,
  handleClick,
  handleClose,
  containerType,
  shippingLine,
  selectedContainers,
  setSelectedContainers }) => {

  const [selectedFields, setSelectedFields] = useState({});

  useEffect(() => {
    if (selectedContainers) {
      const newDict = {};
      selectedContainers.forEach(item => {
        newDict[item.container_number] = item?.container_number;  // Use value as key
      });
      setSelectedFields(newDict);
    }
  }, [selectedContainers])

  const handleSelect = (selected, container_number) => {
    setSelectedFields((prev) => ({
      ...prev,
      [container_number]: selected,
    }));
  };

  const handleSubmit = () => {
    const selectedContainerNumbers = containerData.map(ele => selectedFields[ele?.container_number] && ele.id).filter(Boolean)
    handleClick(selectedContainerNumbers)
  }
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={"xl"}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '16px'
        }
      }}
    >
      <DialogContent sx={{ margin: '1rem' }}>
        <Typography id="alert-dialog-title" sx={{ ...HeadingStyle, textAlign: 'left', paddingTop: "0px", lineHeight: "normal", mb: "1rem" }}>
          {"Select Containers"}
          <Button onClick={() => handleClose(false)} sx={{ float: "right", minWidth: "fit-content" }}>
            <img src={CloseIcon} alt="close" width={"12px"} height={"12px"} />
          </Button>
        </Typography>
        <TableContainer sx={{ borderRadius: '8px' }}>
          <Table
            size="small"
            sx={{ borderRadius: '8px' }}
            aria-label="simple table"
          >
            <TableHead
              sx={{ background: '#ECECEC', borderRadius: '8px 8px 0 0' }}
            >
              <TableRow sx={{ borderRadius: '8px' }}>
                <TableCell sx={TableHeader} align="center">
                  Sr. No
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Container Number
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Type
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Line
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Condition
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Survey Link
                </TableCell>
                <TableCell sx={TableHeader} align="center">
                  Select
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {containerData?.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={TableCellStyle} align="center">
                    {index + 1}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    {row?.container_number}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    {containerType}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    {shippingLine}{' '}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    survey
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    {row?.survey_link ? row?.survey_link : "-"}
                  </TableCell>
                  <TableCell sx={TableCellStyle} align="center">
                    <CheckIcon sx={{
                      cursor: "pointer",
                      borderRadius: "50%",
                      fill: selectedFields?.[row?.container_number] ? "#fff" : "#000000",
                      background: selectedFields?.[row?.container_number] ? "#5FB435" : "#DEDEDE",
                      width: "23px",
                      height: "23px"
                    }}
                      onClick={() => handleSelect(!selectedFields?.[row?.container_number] || false, row?.container_number)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '12px',
                  borderRadius: '8px',
                  m: '1rem 0rem',
                }}
              >
                <IconButton disabled={pages?.prev === undefined} onClick={handlePrev}>
                  <KeyboardArrowLeftIcon />
                </IconButton>
                <Paper sx={{ padding: '0px 12px', borderRadius: '8px' }}>
                  <Typography>{pages?.current}</Typography>
                </Paper>

                <IconButton
                  variant="contained"
                  onClick={handleNext}
                  disabled={pages?.next === undefined}
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
              </Box> */}
        <Button sx={{ ...BookButton }} onClick={handleSubmit} variant="contained" type="submit">
          Update Details
        </Button>
      </DialogContent>
    </Dialog>
  );
};
ExportContainerModalTable.propTypes = {
  containerData: PropTypes.arrayOf(PropTypes.any),
  shippingLine: PropTypes.string,
  containerType: PropTypes.string,
  open: PropTypes.bool,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
  selectedContainers: PropTypes.shape(PropTypes.any),
  setSelectedContainers: PropTypes.func,
};

export default ExportContainerModalTable;