import { styled } from "@mui/material";

export const FormFields = {
  width: "100%",
  background: "#F3F5F8 0% 0% no-repeat padding-box",
  borderRadius: "8px",
  "& .MuiOutlinedInput-input": {
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "25px",
    fontFamily: "Poppins",
    letterSpacing: "0px",
    color: "#878787",
    textTransform: "capitalize",
    "&:focus-visible": {
      borderColor: "#F3F5F8",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": { borderColor: "#F3F5F8 !important" },
};

export const FormInputField = {
  width: "100%",
  background: "#F3F5F8 0% 0% no-repeat padding-box",
  borderRadius: "8px",

  "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  "&.Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "#F3F5F8 !important" },
  },
  "& .MuiOutlinedInput-input": {
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "normal",
    fontSize: {
      xs: "14px",
      sm: "14px",
      md: "14px",
      lg: "16px",
      xl: "18px",
    },
    fontFamily: "Poppins",
    letterSpacing: "0px",
    color: "#000000",
    // textTransform: "capitalize",
    "&:focus-visible": {
      borderColor: "#F3F5F8 !important",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": { borderColor: "#F3F5F8" },
};

export const FormLabelStyle = {
  mb: "0.5rem",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "500",
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "14px",
    lg: "14px",
    xl: "16px",
  },
  lineHeight: "16px",
  fontFamily: "Roboto",
};

export const BackButton = {
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  border: "1px solid #933118",
  fontStyle: "normal",
  fontWeight: "normal", // 'normal' is a valid value for fontWeight
  margin: {
    xs: "0.5rem 0",
    sm: "1rem 0",
    md: "1.2rem 0",
    lg: "1.4rem 0",
    xl: "2rem 0",
  },
  textTransform: "none",
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "14px",
    lg: "14px",
    xl: "16px",
  },
  borderRadius: {
    xs: "2px",
    sm: "2px",
    md: "6px",
    lg: "6px",
    xl: "6px",
  },
  fontFamily: "Poppins",
  letterSpacing: "0px",
  color: "#933118",
  width: "100%",
  marginBottom: "1rem",
  "&:hover": {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #933118",
  },
};

export const ClearButton = {
  border: "1px solid #000000",
  borderRadius: "8px",
  fontStyle: "normal",
  fontWeight: "normal", // 'normal' is a valid value for fontWeight
  fontSize: "28px",
  lineHeight: "42px",
  fontFamily: "Poppins",
  letterSpacing: "0px",
  color: "#000000",
  textTransform: "capitalize",
  width: "100%",
  marginBottom: "1rem",
  "&:hover": {
    border: "1px solid #000000",
  },
};

export const BookButton = {
  width: "100%",
  background: "#933118 0% 0% no-repeat padding-box",
  fontStyle: "normal",
  fontWeight: "normal", // 'normal' is a valid value for fontWeight
  margin: {
    xs: "0.5rem 0",
    sm: "1rem 0",
    md: "1.2rem 0",
    lg: "1.4rem 0",
    xl: "2rem 0",
  },
  borderRadius: {
    xs: "2px",
    sm: "2px",
    md: "6px",
    lg: "6px",
    xl: "6px",
  },
  textTransform: "none",
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "14px",
    lg: "14px",
    xl: "16px",
  },
  fontFamily: "Poppins",
  letterSpacing: "0px",
  color: "#FFFFFF",
  "&:hover": {
    background: "#933118 0% 0% no-repeat padding-box",
  },
};

export const HeadingStyle = {
  fontWeight: 600,
  paddingTop: "2rem",
  fontSize: {
    xs: "14px",
    sm: "20px",
    md: "20px",
    lg: "24px",
    xl: "30px",
  },
  // lineHeight: "41px",
  fontFamily: "Poppins",
  textAlign: "center",
  fontStyle: "normal",
  fontVariant: "normal", // Note: `fontVariant` may not be supported in all browsers
  lineHeight: "41px",
};

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const SecondaryText = {
  fontSize: {
    xs: "12px",
    sm: "12px",
    md: "12px",
    lg: "14px",
    xl: "16px",
  },
  lineHeight: "26px",
  fontFamily: "Poppins",
  fontWeight: "normal",
  fontStyle: "normal",
};

export const TableHeader = {
  fontSize: {
    xs: "12px",
    sm: "12px",
    md: "14px",
    lg: "14px",
    xl: "16px",
  },
  lineHeight: {
    xs: "16px",
    sm: "16px",
    md: "16px",
    lg: "18px",
    xl: "20px",
  },
};

export const TableCellStyle = {
  fontSize: {
    xs: "12px",
    sm: "12px",
    md: "12px",
    lg: "14px",
    xl: "16px",
  },
  lineHeight: {
    xs: "16px",
    sm: "16px",
    md: "16px",
    lg: "18px",
    xl: "20px",
  },
};
