import {
  Backdrop,
  Box,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import HeaderSection from '../../Components/HeaderSection';
import ExportPickUpForm from './ExportPickUpForm';
import ExportPickUpTable from './ExportPickUpTable';
import { useDeletePickupMutation, useGetPickupsMutation } from '../../Services/ExportApi';
import { useSnackbar } from 'notistack';
import { formContainer } from '../../Styles/Import/formSection'
import ExportUpdatePickupModal from './ExportUpdatePickupModal';
import { Helmet } from 'react-helmet-async';

const ExportPickUp = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [editPickUpData, setEditPickUpData] = useState()
  const [pickUpData, setPickUpData] = useState()
  const [showAddPickUp, setShowAddPickUp] = useState(false)
  const [showUpdatePickUp, setShowUpdatePickUp] = useState(false)
  const [getPickups, { isLoading: getPickUpLoading }] = useGetPickupsMutation()
  const [deletePickupApi, { isLoading: deletePickupApiLoading }] = useDeletePickupMutation();
  const [sortType, setSortType] = useState('asc')

  const handleSortChange = (e) => {
    setSortType(e.target.value)
  }

  useEffect(() => {
    const get_pickup_data = async () => {
      const { data } = await getPickups(sortType);
      setPickUpData(data)
    }
    get_pickup_data()
  }, [getPickups, sortType])

  const handleEdit = (values) => {
    setEditPickUpData(values);
    setShowUpdatePickUp(true)
  }

  const handleAddPickUp = () => {
    setEditPickUpData()
    setShowAddPickUp(true)
  }

  const handleDelete = async (id) => {
    const { error } = await deletePickupApi(id)
    if (!error) {
      enqueueSnackbar('deleted Succesfully !', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
      const { data } = await getPickups();
      setPickUpData(data)
    }
    if (error) {
      console.log(error)
    }
  }

  const handleBack = async () => {
    setShowAddPickUp(false)
    const { data } = await getPickups();
    setPickUpData(data);
  }

  const handleClose = async () => {
    setShowUpdatePickUp(false)
    const { data } = await getPickups();
    setPickUpData(data);
  }
  const handlePageChange = async (page) => {
    const { data } = await getPickups({ page });
    setPickUpData(data)
  }
  return (
    <>
      <Helmet>
        <title>Export-Pick-Up | Gati se Pragati</title>
      </Helmet>
      <HeaderSection title="EXPORT-PICK-UP" />
      {showUpdatePickUp &&
        <Box sx={formContainer}>
          <ExportUpdatePickupModal
            open={showUpdatePickUp}
            disableYard={pickUpData?.data?.length !== 1}
            handleClose={handleClose}
            initialFieldOptions={editPickUpData} />
        </Box>}
      {showAddPickUp ? <ExportPickUpForm handleBack={handleBack}
        disableYard={pickUpData?.data?.length > 0}
        defaultYard={pickUpData?.data[0]?.yard?.id}
      /> :
        <ExportPickUpTable
          containerData={pickUpData || {}}
          handleDelete={handleDelete}
          handleAddPickUp={handleAddPickUp}
          handlePageChange={handlePageChange}
          summary={pickUpData?.summary}
          handleEdit={handleEdit}
          handleSortChange={handleSortChange}
          sortType={sortType}
        />
      }

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={getPickUpLoading || deletePickupApiLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ExportPickUp;
