const aboutSectionWrapper = {
  width: {
    xs: "90%",
    sm: "90%",
    md: "90%",
    lg: "85%",
    xl: "80%",
  },
  margin: "3rem auto",
  textAlign: "center",
};

const heading = {
  fontWeight: "bold",
  fontSize: {
    xs: "16px",
    sm: "22px",
    md: "24px",
    lg: "26px",
    xl: "30px",
  },
  fontFamily: "Poppins",
};

const subHeading = {
  fontSize: {
    xs: "20px",
    sm: "24px",
    md: "28px",
    lg: "40px",
    xl: "48px",
  },
  lineHeight: {
    xs: "32px",
    sm: "40px",
    md: "44px",
    lg: "48px",
    xl: "32px",
  },
  fontWeight: "bold",
  fontFamily: "Poppins",
  mb: "4rem",
  color: "#933118",
};

const contentContainer = {
  display: "grid",
  gridTemplateColumns: {
    xs: "1fr",
    sm: "1fr 1fr",
    md: "1.2fr 0.8fr",
    lg: "1.2fr 0.8fr",
    xl: "1.2fr 0.8fr",
  },
  marginTop: {
    xs: "1rem",
    sm: "1rem",
    md: "1rem",
    lg: "2rem",
    xl: "2rem",
  },
  gap: {
    xs: "1rem",
    sm: "1rem",
    md: "1rem",
    lg: "2rem",
    xl: "2rem",
  },
  textAlign: "left",
};

const contentText = {
  fontSize: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
  },
  fontWeight: "normal",
  fontFamily: "Poppins",
  textAlign: "justify",
};

export {
  aboutSectionWrapper,
  heading,
  subHeading,
  contentContainer,
  contentText,
};
