import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ViewIcon from '../../Assets/icons/view-receipt.svg';
import DownloadIcon from '../../Assets/icons/download-icon.svg';
import { PaginationButton, PaginationPageNumber, ScrollBarStyle } from '../../Styles/Common/style';
import { useLazyDownloadInvoiceQuery, useLazyViewInvoiceDetailQuery } from '../../Services/AccountApi';
import ViewInvoice from './ViewInvoice';
import { enqueueSnackbar } from 'notistack';
import { TableCellStyle, TableHeader } from '../../Styles/Form/Field';
import GlobalLoader from '../../Components/GlobalLoader';

const InvoiceTable = ({ tableData, selectedType, handlePageChange }) => {
  const [viewInvoiceApi, { isLoading }] = useLazyViewInvoiceDetailQuery();
  const [downlaodInvoiceApi, { isLoading: downloadApiLoading }] = useLazyDownloadInvoiceQuery()
  const [viewInvoiceData, setViewInvoiceData] = useState()
  const [viewModal, setViewModal] = useState(false)
  const handlePrev = () => {
    if (tableData?.previous) {
      handlePageChange(tableData?.previous)
    }
  };
  const handleNext = () => {
    if (tableData?.next) {
      handlePageChange(tableData?.next)
    }
  };
  const handleView = async (row) => {
    const { data, error } = await viewInvoiceApi({ order_id: row?.order_id })
    if (data) {
      setViewInvoiceData(data?.html_content);
      setViewModal(true);
    }
    if (error) {
      setViewInvoiceData()
    }
  }
  const handleDownload = async (row) => {
    console.log(row?.order_id)
    const { data, error } = await downlaodInvoiceApi({ order_id: row?.order_id })
    if (data) {
      enqueueSnackbar("Download Started !", {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
    }
    if (error) {
      enqueueSnackbar(error?.data?.message || "Something went wrong !", {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
      console.log(error)
    }
  }

  return (
    <>
      <TableContainer sx={{ borderRadius: '8px', maxHeight: "600px", ...ScrollBarStyle }}>
        <Table sx={{ minWidth: 650, borderRadius: '8px', textWrap: 'nowrap' }} aria-label="simple table">
          <TableHead sx={{ background: '#ECECEC', borderRadius: '8px 8px 0 0' }}>
            <TableRow sx={{ borderRadius: '8px' }}>
              <TableCell sx={TableHeader} align="center">
                Sr.No
              </TableCell>
              <TableCell sx={TableHeader} align="center">Order ID</TableCell>
              <TableCell sx={TableHeader} align="center">Date</TableCell>
              <TableCell sx={TableHeader} align="center">Size Type</TableCell>
              <TableCell sx={TableHeader} align="center">Containers</TableCell>
              <TableCell sx={TableHeader} align="center">Shipping Line</TableCell>
              <TableCell sx={TableHeader} align="center">{selectedType === 'import' ? 'Do Number' : 'Booking Number'}</TableCell>
              <TableCell sx={TableHeader} align="center">{' '}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.data?.map((row, index) => (
              <TableRow
                key={(tableData?.current_page - 1) * tableData?.per_page + index + 1}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell sx={TableCellStyle} align="center">
                  {(tableData?.current_page - 1) * tableData?.per_page + index + 1}
                </TableCell>
                <TableCell sx={TableCellStyle} align="center">{row?.converted_order_id}</TableCell>
                <TableCell sx={TableCellStyle} align="center">{row?.payment_date}</TableCell>
                <TableCell sx={TableCellStyle} align="center">{row?.do_number ?
                  [...new Set(row?.containers?.map(ele => ele?.container_size_type?.container_size_type))].join(", ") :
                  row?.container_size_type?.container_size_type}</TableCell>
                <TableCell sx={{ ...TableCellStyle }} align="center">
                  {row?.containers?.map(ele => ele?.container_number)?.join(", ")}
                </TableCell>
                <TableCell sx={TableCellStyle} align="center">{row?.shipping_line?.name}</TableCell>
                <TableCell sx={TableCellStyle} align="center">{row?.bn_number ? row?.bn_number : row?.do_number}</TableCell>
                <TableCell sx={TableCellStyle} align="center">
                  <IconButton sx={{ background: "#FFFFFF 0% 0% no-repeat padding-box", border: "1px solid #933118", borderRadius: "4px", width: "30px", height: "30px", ':hover': { background: "#FFFFFF", } }} color='success' onClick={() => handleView(row)}>
                    <img src={ViewIcon} alt="" />
                  </IconButton>
                  &emsp;
                  <IconButton sx={{ borderRadius: "5px", width: "30px", height: "30px", background: "#933118", ':hover': { background: "#933118", } }} color='error' onClick={() => handleDownload(row)}>
                    <img src={DownloadIcon} alt="" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {tableData?.data?.length > 0 ?
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '12px',
            borderRadius: '8px',
            padding: "1rem",
            m: '1rem 0rem',
          }}
        >
          <IconButton sx={{
            ...PaginationButton
          }} disabled={tableData?.previous === null} onClick={handlePrev}>
            <KeyboardArrowLeftIcon sx={{ fill: tableData?.previous === null ? "#000000" : "#FFFFFF" }} />
          </IconButton>
          <Paper sx={{ padding: '0px 12px', borderRadius: '8px', ...PaginationPageNumber }}>
            <Typography>{tableData?.current_page}</Typography>
          </Paper>

          <IconButton
            variant="contained"
            onClick={handleNext}
            disabled={tableData?.next === null}
            sx={{
              ...PaginationButton
            }}
          >
            <KeyboardArrowRightIcon sx={{ fill: tableData?.next === null ? "#000000" : "#FFFFFF" }} />
          </IconButton>
        </Box> :
        <Typography sx={{
          minHeight: '5rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 600
        }}>No Data Found</Typography>
      }
      {viewInvoiceData && <ViewInvoice htmlData={viewInvoiceData} open={viewModal} handleClose={() => setViewModal(false)} />}
      <GlobalLoader open={Boolean(isLoading || downloadApiLoading)} />
    </>
  )
}

InvoiceTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.any),
  selectedType: PropTypes.string,
  handlePageChange: PropTypes.func,
};

export default InvoiceTable