const brandTitle = {
  fontSize: {
    xs: "22px",
    sm: "24px",
    md: "28px",
    lg: "30px",
    xl: "37px",
  },
  lineHeight: {
    xs: "30px",
    sm: "32px",
    md: "38px",
    lg: "42px",
    xl: "45px",
  },
  fontFamily: "Open Sans",
  fontWeight: "bold",
  letterSpacing: 0,
  color: "#000000",
  textAlign: "center",
};

const brandSubTitle = {
  fontSize: {
    xs: "18px",
    sm: "18px",
    md: "20px",
    lg: "22px",
    xl: "24px",
  },
  fontFamily: "Open Sans",
  color: "#000000",
  textAlign: "center",
  mb: "1rem",
};

const footerContainer = {
  // display: "grid",
  // gridTemplateColumns: {
  //   xs: "1fr",
  //   sm: "1fr",
  //   md: "1fr 2fr 1fr",
  //   lg: "1fr 2fr 1fr",
  //   xl: "1fr 2.5fr 1fr",
  // },
  // gap: {
  //   xs: "1rem",
  //   sm: "1rem",
  //   md: "1rem",
  //   lg: "1.5rem",
  //   xl: "2rem",
  // },
  // width: {
  //   xs: "96%",
  //   sm: "84%",
  //   md: "90%",
  //   lg: "96%",
  //   xl: "96%",
  // },
  // margin: "0 auto",
};

const footerLinksWrapper = {
  justifyContent: "center",
  width: "100%",
  display: "flex",
};

const footerCategoriesTitle = {
  fontSize: {
    xs: "14px",
    sm: "16px",
    md: "16px",
    lg: "18px",
    xl: "20px",
  },
  textAlign: {
    xs: "center",
    sm: "center",
    md: "left",
    lg: "left",
    xl: "left",
  },
  fontWeight: "600",
  lineHeight: "32px",
  fontFamily: "Poppins",
};

const footerSubCategory = {
  fontSize: {
    xs: "14px",
    sm: "16px",
    md: "16px",
    lg: "18px",
    xl: "20px",
  },
  textAlign: {
    xs: "center",
    sm: "center",
    md: "center",
    lg: "left",
    xl: "left",
  },
  lineHeight: "32px",
  fontFamily: "Poppins",
};

const socialLinksContainer = {
  display: "flex",
  gap: "1rem",
  mt: "2rem",
  justifyContent: {
    xs: "center",
    sm: "center",
    md: "center",
    lg: "center",
    xl: "center",
  },
};

const socialLinks = {
  display: "flex",
  cursor: "pointer",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#933118",
  padding: "0.5rem",
  borderRadius: "50%",
  width: {
    xs: "40px",
    sm: "40px",
    md: "40px",
    lg: "40px",
    xl: "40px",
  },
  height: {
    xs: "40px",
    sm: "40px",
    md: "40px",
    lg: "40px",
    xl: "40px",
  },
};

const socialIcons = {
  color: "#ffffff",
};

const footerCopyRightConatiner = {
  marginTop: "1rem",
  backgroundColor: "#933118",
};

export {
  brandTitle,
  brandSubTitle,
  socialLinks,
  footerCategoriesTitle,
  footerSubCategory,
  footerContainer,
  footerLinksWrapper,
  socialLinksContainer,
  socialIcons,
  footerCopyRightConatiner,
};
