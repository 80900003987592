import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FormLabelStyle } from '../Styles/Form/Field';
const CustomSelectField = ({
  name,
  value,
  disable,
  placeholder,
  label,
  onChange,
  onBlur,
  error,
  options,
  helperText,
  required,
  optionError
}) => {
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 200, // Adjust this value to change the height of the options list
      },
    },
  };
  return (
    <FormControl fullWidth error={error} disabled={disable}>
      <Typography
        sx={{ ...FormLabelStyle, ...(disable && { color: 'grey !important' }) }}
      >
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <Select
        MenuProps={menuProps}
        name={name}
        id={name}
        value={value}
        displayEmpty
        IconComponent={KeyboardArrowDownIcon}
        sx={{
          '& .MuiSelect-select': {
            backgroundColor: '#F3F5F8',
            color: '#000000',
            borderRadius: '8px',
            fontSize: {
              xs: '12px',
              sm: '14px',
              md: '14px',
              lg: '16px',
              xl: '18px',
            },
          },
          '& .muiSelect-outlined': {
            borderRadius: {
              xs: '2px',
              sm: '2px',
              md: '4px',
              lg: '8px',
              xl: '8px',
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: () => `${(error) ? "1px solid red" : 'none'}`,
            borderRadius: {
              xs: '2px',
              sm: '2px',
              md: '4px',
              lg: '8px',
              xl: '8px',
            }
          },
        }}
        // sx={{ ...FormFields }}
        inputProps={{ 'aria-label': 'Without label' }}
        onChange={onChange}
        onBlur={onBlur}
        defaultValue=""
      >
        <MenuItem key="select" value="">
          Select
        </MenuItem>
        {options?.map((item) => (
          <MenuItem key={item?.name} value={item?.id}>
            {item?.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        {helperText}
        {optionError &&
          <span style={{ fontSize: '12px', color: 'darkred' }}>({optionError})</span>
        }
      </FormHelperText>
    </FormControl>
  );
};
CustomSelectField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  disable: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.any),
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  optionError: PropTypes.string,
};

export default CustomSelectField;
