import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import HomePage from "./Pages/HomePage";
import ImportForm from "./Pages/ImportSections/ImportForm";
import { default as ImportServices } from "./Pages/ImportSections/Services";
import { default as ExportServices } from "./Pages/ExportSection/Services";
import ExportPickUp from "./Pages/ExportSection/ExportPickUp";
import Wallet from "./Pages/Wallet";
import Billing from "./Pages/Billing/Billing";
import Invoice from "./Pages/Billing/Invoice";
import { getAccessToken, logOut } from "./Services/authService";
import { useEffect } from "react";
import { useGetUserDetailsMutation } from "./Services/UserApi";
import ExportPickUpTable from  './Pages/ExportSection/ExportPickUpTable'
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetails, updateUserTokens } from "./Store/userSlice";
import PaymentSuccess from "./Pages/Billing/PaymentSuccess";
import PaymentFailed from "./Pages/Billing/PaymentFailed";
import TransactionDetails from "./Pages/Wallet/TransactionDetails";
import BillingTransactionDetails from "./Pages/Billing/BillingTransactionDetails";

function App() {
  const userDetails = useSelector(state=> state?.user?.user)
  const dispatch = useDispatch();
  useEffect(()=>{
    const getData = async () =>{
      const extractedTokens = getAccessToken();
      dispatch(updateUserTokens(extractedTokens));
      if (!extractedTokens){
        dispatch(updateUserDetails(null));
        logOut();
      }
    }
    if(!userDetails?.email){
      getData()
    }
  },[])

  return (
    <div style={{display:"flex", flexDirection:"column",  height: "100vh"}}>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/import/services" element={<ImportServices />} />
          <Route path="/import/drop-off" element={<ImportForm />} />
          <Route path="/export/services" element={<ExportServices />} />
          <Route path="/export/pick-up" element={<ExportPickUp />} />
          <Route path="/export/pick-up-table" element={<ExportPickUpTable />} />
          <Route path="/wallet/wallet-recharge" element={<Wallet />} />
          <Route path="/wallet/transaction-detail" element={<TransactionDetails />} />
          <Route path="/billing/billing" element={<Billing />} />
          <Route path="/billing/invoices" element={<Invoice />} />
          <Route path="/billing/transaction-detail" element={<BillingTransactionDetails />} />
          <Route path="/payment/success" element={<PaymentSuccess />} />
          <Route path="/payment/fail" element={<PaymentFailed />} />
          <Route path="/wallet/callback" element={<Wallet />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
