import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  services: { import: false, export: false },
  tokens: null,
};

export const userSlice = createSlice({
  name: "user-info",
  initialState,
  reducers: {
    updateUserTokens: (state, action) => {
      if (action.payload === null) {
        state.tokens = null;
      }
      if (typeof action.payload === "string") {
        state.tokens.access = action.payload;
      } else {
        state.tokens = {
          access: action.payload?.access,
          refresh: action.payload?.refresh,
        };
      }
    },

    updateUserDetails: (state, action) => {
      if (action.payload) {
        state.user = action.payload;
      } else {
        state.tokens = null;
        state.user = null;
      }
    },

    updateImportService: (state, action) => {
      if (action.payload) {
        state.services.import = action.payload;
      } else {
        state.services.import = false;
      }
    },

    updateExportService: (state, action) => {
      if (action.payload) {
        state.services.export = action.payload;
      } else {
        state.services.export = false;
      }
    },
  },
});

export const {
  updateUserDetails,
  updateUserTokens,
  updateImportService,
  updateExportService,
} = userSlice.actions;

export default userSlice.reducer;
