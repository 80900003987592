import Cookies from "js-cookie";

// eslint-disable-next-line no-undef
const AUTH_URL = process.env.REACT_APP_AUTH_URL;
const DOMAIN = process.env.REACT_APP_TOKEN_COOKIES_DOMAIN;
// Function to determine if the current environment is localhost
const isDevelopment = process.env.REACT_APP_DEVELOPMENT;
const doLogIn = (values) => {
  // Serialize dictionary to JSON string
  const dictString = JSON.stringify(values);
  const expires = 1; // Set cookie to expire in 1 day

  Cookies.set("tokens", dictString, {
    expires: expires,
    path: "/",
    domain: DOMAIN,
    secure: !isDevelopment, // Set secure to true in production, false for localhost
    sameSite: isDevelopment ? "Lax" : "None",
  });
  window.location.href = AUTH_URL;
};

const updateToken = (accessToken) => {
  if (accessToken) {
    let values = {
      refresh: getAccessToken().refresh,
      access: accessToken,
    };
    // Serialize dictionary to JSON string
    const dictString = JSON.stringify(values);
    const expires = 1; // Set cookie to expire in 1 day

    Cookies.set("tokens", dictString, {
      expires: expires,
      path: "/",
      domain: DOMAIN,
      secure: !isDevelopment, // Set secure to true in production, false for localhost
      sameSite: isDevelopment ? "Lax" : "None",
    });
  }
};

const isLoggedIn = () => {
  return Cookies.get("tokens") || null;
};

const getAccessToken = () => {
  const tokenString = Cookies.get("tokens");
  if (tokenString) {
    try {
      return JSON.parse(tokenString);
    } catch (e) {
      console.error("Failed to parse tokens cookie:", e);
      return null;
    }
  }
  return null;
};

const logOut = () => {
  Cookies.remove("tokens", {
    path: "/",
    domain: DOMAIN,
    secure: !isDevelopment, // Set secure to true in production, false for localhost
    sameSite: isDevelopment ? "Lax" : "None",
  });
  window.location.href = AUTH_URL;
};

export { doLogIn, isLoggedIn, logOut, getAccessToken, updateToken };
