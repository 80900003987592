import { Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import React from 'react'
import PropTypes from "prop-types";
const TitleStyle = {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '28px',
    fontFamily: 'Poppins, sans-serif',
    letterSpacing: '0px',
    color: '#000000',
};
const ModalStyle = {
    backgroundColor: '#FFFFFF',
    backgroundPosition: '0% 0%',
    backgroundRepeat: 'no-repeat',
    padding: '10px',
    border: '1px solid #707070',
    borderRadius: '16px',
};

const SubHeadingStyle = {
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '28px',
    fontFamily: 'Poppins, sans-serif',
    letterSpacing: '0px',
    color: '#000000',
    marginTop: "1rem",
    marginBottom: "2rem",
    textTransform: 'capitalize',
    opacity: 1,
};
const CancelButtonStyle = {
    backgroundColor: '#FFFFFF',
    backgroundPosition: '0% 0%',
    backgroundRepeat: 'no-repeat',
    padding: '9px 30px', // Assuming no padding, adjust if needed
    border: '1px solid #933118',
    borderRadius: '8px',
    color: "#933118",
    opacity: 1,
    ":hover": {
        backgroundColor: '#FFFFFF',
        border: '1px solid #933118',
        color: "#933118",
    }
};
const DeleteButtonStyle = {
    backgroundColor: '#933118',
    backgroundPosition: '0% 0%',
    backgroundRepeat: 'no-repeat',
    padding: '10px 30px',
    borderRadius: '8px',
    border: '1px solid #933118',
    opacity: 1,
    color: "#fff",
    ":hover": {
        backgroundColor: '#933118',
        border: '1px solid #933118',
        color: "#fff",
    }
};

const DeleteConfirmation = ({ open, handleClose, handleConfirmDelete, dataTitle }) => {

    return (
        <Dialog
            fullWidth
            maxWidth={'md'}
            open={open}
            PaperProps={{
                sx: { ...ModalStyle }
            }}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent sx={{ padding: "20px" }}>
                <Typography sx={TitleStyle}>Confirm the action</Typography>
                <Typography sx={SubHeadingStyle} textAlign="center">Do You Really Want To Delete This {dataTitle} Data?</Typography>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item padding="0px" width="fit-content">
                        <Button sx={{ ...CancelButtonStyle }} onClick={() => handleClose()} variant="outlined">
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item padding="0px" width="fit-content" >
                        <Button sx={{ ...DeleteButtonStyle }} variant="outlined" type="button" onClick={handleConfirmDelete} >
                            Delete
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

DeleteConfirmation.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    dataTitle: PropTypes.string,
    handleConfirmDelete: PropTypes.func,
}

export default DeleteConfirmation;
