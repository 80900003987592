const ServicesDescContainer = {
  width: {
    xs: "96%",
    sm: "90%",
    md: "90%",
    lg: "90%",
    xl: "90%",
  },
  backgroundColor: "#F3F5F8",
  m: "3rem auto",
  p: {
    xs: "1.5rem",
    sm: "1.8rem",
    md: "2rem",
    lg: "2.2rem",
    xl: "2.5rem",
  },
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  gap: {
    xs: "1rem",
    sm: "1.2rem",
    md: "1.4rem",
    lg: "1.8rem",
    xl: "2rem",
  },
};

const serviceDescHeading = {
  fontWeight: 600,
  fontSize: {
    xs: "24px",
    sm: "28px",
    md: "32px",
    lg: "46px",
    xl: "32px",
  },
  lineHeight: "41px",
  color: "#000000",
};

const serviceDesc = {
  fontSize: {
    xs: "14px",
    sm: "16px",
    md: "18px",
    lg: "20px",
    xl: "24px",
  },
  lineHeight: "36px",
  fontFamily: "Poppins",
  color: "#000000",
};
const HeaderStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: {
    xs: "14px",
    sm: "20px",
    md: "22px",
    lg: "24px",
    xl: "34px",
  },
  fontWeight: 600,
  lineHeight: "32px",
  fontStyle: "normal",
  fontVariant: "normal",
  margin: "1rem 0 2rem 0",
};
const SubHeaderstyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "18px",
    lg: "20px",
    xl: "22px",
  },
  fontWeight: 600, // 'medium' corresponds to a weight of 500
  lineHeight: "35px",
  fontStyle: "normal",
  fontVariant: "normal",
};
const TextStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
  },
  fontWeight: "normal",
  lineHeight: "52px",
  fontStyle: "normal",
  fontVariant: "normal",
};
const ButtonStyle = {
  fontFamily: "Open Sans, sans-serif",
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "30px",
  letterSpacing: "0px",
  color: "#FFFFFF",
  textTransform: "capitalize",
  fontStyle: "normal",
  fontVariant: "normal",
  minWidth: "200px",
  borderColor: "#933118",
  borderRadius: "30px",
  padding: "20px",
};
export {
  ServicesDescContainer,
  serviceDescHeading,
  serviceDesc,
  HeaderStyle,
  SubHeaderstyle,
  TextStyle,
  ButtonStyle,
};
