import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { HeaderStyle, SubHeaderstyle, TextStyle } from '../../Styles/componentStyles/ServicesSection'
import { useLocation } from 'react-router-dom';
import PaymentSuccessIcon from '../../Assets/icons/payment-success-icon.svg'
import { Helmet } from 'react-helmet-async';
const PaymentSuccess = () => {
  const [timeLeft, setTimeLeft] = useState(3);

  useEffect(() => {
    if (timeLeft === 0) {
      window.close();
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  useEffect(() => {
    setTimeout(() => {
      window.close();
    }, 5000);
  }, [])

  return (
    <Box sx={{ flexGrow: "1" }}>
      <Helmet>
        <title>Payment | Gati se Pragati</title>
      </Helmet>
      <Box sx={{
        maxWidth: {
          xs: "90%",
          sm: "80%",
          md: "70%",
          lg: "70%",
          xl: "70%",
        }, margin: " 4rem auto "
      }}>
        <Box sx={{
          width: {
            xs: "100%",
            sm: "80%",
            md: "60%",
            lg: "40%",
            xl: "40%",
          }, margin: "auto"
        }}>
          <Typography textAlign={'center'} sx={{
            "img": {
              width: {
                xs: "40px",
                sm: "40px",
                md: "80px",
                lg: "80px",
                xl: "80px",
              }
            }
          }}>
            <img src={PaymentSuccessIcon} alt="payment success" />
          </Typography>
          <Typography sx={{ ...HeaderStyle, margin: "1rem 0px 2rem 0px" }} textAlign={'center'}>
            PAYMENT RECEIVED
          </Typography>
          <Typography sx={SubHeaderstyle} textAlign={'center'}>Your Order Id is {searchParams?.get('transactionid')}</Typography>
          <Typography sx={{
            ...TextStyle, lineHeight: {
              xs: "18px",
              sm: "18px",
              md: "20px",
              lg: "22px",
              xl: "24px",
            }, mt: "1rem"
          }} textAlign={'center'}>This tab will close automatically in {timeLeft} seconds.</Typography>
          {/* <Typography sx={TextStyle} textAlign={'center'}>Receipt of Booking</Typography>
            <Box sx={{margin:"1rem 0 4rem 0"}}>
                <Box sx={{display:"flex",gap:2, margin:"auto", width:"100%", justifyContent:"center"}}>
                    <Chip sx={{...ButtonStyle,color:"#933118"}} label="View" variant="outlined" onClick={handleView} />
                    <Chip sx={{...ButtonStyle,background: '#933118 0% 0% no-repeat padding-box',":hover":{ background: '#933118 0% 0% no-repeat padding-box'}}} label="Download" onClick={handleDownload} />
                </Box>
            </Box> */}
        </Box>
      </Box>
    </Box>
  )
}

export default PaymentSuccess