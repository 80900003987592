import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useRemoveImportContainerDetailsMutation } from '../../Services/ImportApi';
import { useState } from 'react';
import ImportEditDialog from './ImportEditDialog';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { DeleteIconButtonStyle, EditIconButtonStyle, PaginationButton, PaginationPageNumber, ScrollBarStyle } from '../../Styles/Common/style';
import EditButtonIcon from '../../Assets/icons/edit-icon.svg';
import DeleteButtonIcon from '../../Assets/icons/delete-icon.svg';
import DeleteConfirmation from '../../Components/DeleteConfirmation';
import TotalAmountSummary from '../ExportSection/TotalAmountSummary';
import { TableCellStyle, TableHeader } from '../../Styles/Form/Field';

const ImportsTable = ({ initialFieldOptions, containerDetails, handleContainerDetails, summary, handlePageChange }) => {
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState("")

    const handlePrev = () => {
        if (containerDetails?.previous) {
            handlePageChange(containerDetails?.previous)
        }
    };
    const handleNext = () => {
        if (containerDetails?.next) {
            handlePageChange(containerDetails?.next)
        }
    };
    const [removeDropOffContainer] = useRemoveImportContainerDetailsMutation()

    const [open, setOpen] = useState(false)
    const [data, setData] = useState(null)

    const handleClose = () => {
        handleContainerDetails()
        setOpen(false)
    }

    const handleItemEdit = (row) => {
        setData(row)
        setOpen(true)
    }
    const { enqueueSnackbar } = useSnackbar();
    const handleDeleteContainer = async () => {
        const response = await removeDropOffContainer(deleteId)
        if (response?.data == null) {
            enqueueSnackbar('Pick-off Deleted Successfully !', {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });
            await handleContainerDetails()
        }
        else {
            enqueueSnackbar('something went wrong while removing pick off container', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });
        }
    }

    const openDeleteModal = (id) => {
        setDeleteId(id);
        setConfirmDeleteModal(true)
    }

    const closeDeleteModal = () => {
        setDeleteId("");
        setConfirmDeleteModal(false)
    }

    const handleDeletePickUp = () => {
        handleDeleteContainer()
        setDeleteId("");
        setConfirmDeleteModal(false)

    }


    return (
        <>
            <TableContainer sx={{ borderRadius: '8px', ...ScrollBarStyle }}>
                <Table sx={{ minWidth: 650, borderRadius: '8px', textWrap: 'nowrap' }} aria-label="simple table">
                    <TableHead sx={{ background: '#ECECEC', borderRadius: '8px 8px 0 0' }}>
                        <TableRow sx={{ borderRadius: '8px' }}>
                            <TableCell sx={TableHeader} align="center">Sr.No</TableCell>
                            <TableCell sx={TableHeader} align="center">Date</TableCell>
                            <TableCell sx={TableHeader} align="center">Yard</TableCell>
                            <TableCell sx={TableHeader} align="center">Shipping Line</TableCell>
                            <TableCell sx={TableHeader} align="center">Size Type</TableCell>
                            <TableCell sx={TableHeader} align="center">Container Number</TableCell>
                            <TableCell sx={TableHeader} align="center">DO Number</TableCell>
                            <TableCell sx={TableHeader} align="center">Amount</TableCell>
                            <TableCell sx={TableHeader} align="center">GST</TableCell>
                            <TableCell sx={TableHeader} align="center">Total</TableCell>
                            <TableCell sx={TableHeader} align="center">{' '}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ background: '#fff' }}>
                        {containerDetails?.data?.map((row, index) => (
                            <TableRow
                                key={(containerDetails?.current_page - 1) * containerDetails?.per_page + index + 1}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={TableCellStyle} align="center">
                                    {(containerDetails?.current_page - 1) * containerDetails?.per_page + index + 1}
                                </TableCell>
                                <TableCell sx={TableCellStyle} align="center">{dayjs(row?.schedule_date?.date).format('DD-MM-YYYY')}</TableCell>
                                <TableCell sx={TableCellStyle} align="center">{row?.yard?.name}</TableCell>
                                <TableCell sx={TableCellStyle} align="center">{row?.shipping_line?.name}</TableCell>
                                <TableCell sx={TableCellStyle} align="center">{[...new Set(row?.containers?.map(ele => ele?.container_size_type?.container_size_type))].join(", ")}</TableCell>
                                <TableCell sx={TableCellStyle} align="center">{row?.containers?.map(ele => ele?.container_number)?.join(", ")}</TableCell>
                                <TableCell sx={TableCellStyle} align="center">{row?.do_number}</TableCell>
                                <TableCell sx={TableCellStyle} align="center">{row?.amount} ₹</TableCell>
                                <TableCell sx={TableCellStyle} align="center">{row?.gst}</TableCell>
                                <TableCell sx={TableCellStyle} align="center">{row?.total_amount} ₹</TableCell>
                                <TableCell sx={TableCellStyle} align="center">
                                    <IconButton sx={{ ...EditIconButtonStyle }} color='success' onClick={() => handleItemEdit(row)}>
                                        <img src={EditButtonIcon} alt="" />
                                    </IconButton>&emsp;
                                    <IconButton sx={{ ...DeleteIconButtonStyle }} color='error' onClick={() => openDeleteModal(row?.id)}>
                                        <img src={DeleteButtonIcon} alt="" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </TableContainer>
            {summary && <TotalAmountSummary summary={summary} />}
            {
                containerDetails?.data?.length > 0 ?
                    (containerDetails?.previous || containerDetails?.next) &&
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '12px', borderRadius: '8px', background: "#fff", padding: '1rem 0rem' }}>
                        <IconButton
                            sx={{
                                ...PaginationButton
                            }}
                            disabled={containerDetails?.previous === null}
                            onClick={handlePrev}>
                            <KeyboardArrowLeftIcon sx={{ fill: containerDetails?.previous === null ? "#000000" : "#FFFFFF" }} />
                        </IconButton>
                        <Paper sx={{ padding: '0px 12px', borderRadius: '8px', ...PaginationPageNumber }}>
                            <Typography>{containerDetails?.current_page}</Typography>
                        </Paper>

                        <IconButton
                            sx={{
                                ...PaginationButton
                            }}
                            variant="contained"
                            onClick={handleNext}
                            disabled={containerDetails?.next === null}
                        >
                            <KeyboardArrowRightIcon sx={{ fill: containerDetails?.next === null ? "#000000" : "#FFFFFF" }} />
                        </IconButton>
                    </Box>
                    :
                    <Typography sx={{
                        minHeight: '5rem',
                        fontSize: {
                            xs: "14px",
                            sm: "14px",
                            md: "14px",
                            lg: "14px",
                            xl: "16px",
                        },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 600
                    }}>No Data Found</Typography>
            }
            {confirmDeleteModal && <DeleteConfirmation dataTitle="Drop-Off" handleClose={closeDeleteModal} open={confirmDeleteModal} handleConfirmDelete={handleDeletePickUp} />}
            {open &&
                <ImportEditDialog
                    open={open}
                    disableYard={containerDetails?.data?.length !== 1}
                    handleClose={handleClose}
                    formdata={data}
                    initialFieldOptions={initialFieldOptions}
                />
            }
        </>
    )
}

ImportsTable.propTypes = {
    initialFieldOptions: PropTypes.any.isRequired,
    summary: PropTypes.shape(PropTypes.any),
    containerDetails: PropTypes.any,
    handleContainerDetails: PropTypes.func.isRequired,
    handlePageChange: PropTypes.func.isRequired
}

export default ImportsTable