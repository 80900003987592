import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types';

const GlobalLoader = ({ open }) => {
    return (
        <Backdrop
            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

GlobalLoader.propTypes = {
    open: PropTypes.bool.isRequired
}

export default GlobalLoader