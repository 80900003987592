import React from 'react';
import { Box, FormControl, FormHelperText, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { FormLabelStyle, VisuallyHiddenInput } from '../Styles/Form/Field';
import AttachIcon from '../Assets/icons/attachIcon.svg';
const CustomModalUploadField = ({
  name,
  label,
  onChange,
  onBlur,
  error,
  helperText,
  value,
  required,
}) => {
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer?.files?.[0]) {
      // Handle the dropped files here
      onChange(e?.dataTransfer?.files[0] || null);
    }
  };

  const handleChange = (file) => {
    onChange(file);
  };
  const truncateName = (name, maxLength = 10) => {
    if (name?.length > maxLength) {
      return name.substring(0, maxLength) + '...';
    }
    return name;
  }

  return (
    <>
      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
          transition: 'border .24s ease-in-out',
          border: "1px solid grey",
          background: '#fff 0% 0% no-repeat padding-box',
          borderRadius: '8px',
          padding: '0px',
          minHeight: '56px',
          marginBottom: '1rem',
        }}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <FormControl fullWidth>
          <label
            htmlFor={name}
            style={{ color: '#959595', fontSize: 'larger' }}
          >
            <VisuallyHiddenInput
              type="file"
              id={name}
              name={name}
              onBlur={onBlur}
              onChange={(event) => {
                const file = event?.currentTarget?.files?.[0] || null;
                handleChange(file)
                event.target.value = null;
              }
              }
            />
            <span style={{ padding: '8px', display: 'flex', cursor: 'pointer' }}>
              <Box sx={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 1,
                background: '#fff 0% 0% no-repeat padding-box', p: '0.4rem',
                borderRadius: '8px'
              }}>
                <img src={AttachIcon} alt="" height='24px' width='24px' />
              </Box>
              &nbsp;
              <span
                style={{
                  fontSize: '1rem',
                  fontFamily: 'Poppins',
                  letterSpacing: '0px',
                  color: '#000000',
                  textTransform: 'uppercase',
                  margin: 'auto 0',
                }}
              >
                {value?.name ? truncateName(value?.name) : truncateName(value) || 'Attach BN'}
              </span>
            </span>
          </label>
        </FormControl>
      </Box>
      {helperText && (
        <FormHelperText sx={{ marginLeft: '14px' }} error>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

CustomModalUploadField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
};

export default CustomModalUploadField;
