import { Backdrop, Box, Button, CircularProgress, Grid, InputAdornment, InputBase, Typography } from '@mui/material';
import HeaderSection from '../../Components/HeaderSection';
import { formContainer, formTitle } from '../../Styles/Import/formSection';
import { useEffect, useState } from 'react';
import { useFetchWalletDetailQuery, useRechargeWalletMutation, useWalletCallBackMutation } from '../../Services/AccountApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { ReactComponent as RefreshSvgIcon } from '../../Assets/icons/refresh-icon.svg';
import { RefreshIconButtonStyle } from '../../Styles/Common/style';
const Wallet = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search);
    const order_id =  searchParams?.get('transactionid')
    const [walletCallback] =useWalletCallBackMutation()
    const [amount, setAmount] = useState(0)
    const { isLoading, data: walletDetail, refetch:walletDetailRefetch } = useFetchWalletDetailQuery(null);
    const [rechargeWallet, { isLoading: rechargeWalletApiLoading }] = useRechargeWalletMutation();

    useEffect(()=>{
        const verifyOrder = async () =>{
          const {data, error} = await walletCallback({transactionId:order_id})
          if(data){
            enqueueSnackbar("Payment Succesfull !", {
              variant: 'success',
              anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });
            walletDetailRefetch()
            navigate("/wallet/manage-wallet", { replace: true });
          }
          else{
            console.log(error)
          }
        }
        if(order_id){
          verifyOrder()
        }
      },[order_id, walletCallback, navigate, walletDetailRefetch])

    useEffect(()=>{
        walletDetailRefetch()
    },[])

    const handleRefresh = () =>{
        walletDetailRefetch()
    }

    const handlePayment = async () =>{
        if(amount > 0){
            const {data, error} = await rechargeWallet({amount:amount});
            if(data){
                window.location.href = data?.paymentUrl
            }
            if (error){
                console.log(error)
            }
        }
    }
    return (
        <Box>
            <HeaderSection title="WALLET" />
            <Box sx={formContainer}>
                    <Box display={'flex'}>
                        <Typography flex={'1'} sx={formTitle}> Wallet Details Form </Typography>
                        <Box sx={{...RefreshIconButtonStyle}} onClick={handleRefresh}>
                            <RefreshSvgIcon width={"100%"} height={"100%"} rotate={"90deg"} />
                        </Box>
                    </Box>
                    
                    <Grid container spacing={5} sx={{ mt: '0px' }}>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{
                                fontWeight: '600',
                                fontFamily: 'Roboto',
                                fontSize: '24px',
                                lineHeight: '25px',
                                mb: '0.5rem'
                            }}>Amount in Wallet</Typography>
                            <InputBase
                                startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                id="wallet_amount"
                                name="wallet_amount"
                                value={walletDetail?.data?.balance?.toFixed(2) || 0}
                                disabled
                                sx={{ background: '#F3F5F8', p: '0.7rem 1rem', width: '100%', borderRadius: '16px' }}
                                placeholder="Recharge Amount"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{
                                fontWeight: '600',
                                fontFamily: 'Roboto',
                                fontSize: '24px',
                                lineHeight: '25px',
                                mb: '0.5rem'
                            }}>Recharge Amount</Typography>
                            <InputBase
                                startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                id="recharge_amount"
                                name="recharge_amount"
                                type="number"
                                value={amount}
                                onChange={(event) => setAmount(event?.target?.value)}
                                sx={{ background: '#F3F5F8', p: '0.7rem 1rem', width: '100%', borderRadius: '16px' }}
                                placeholder="Enter Amount"
                            />
                        </Grid>
                    </Grid>
                    {/* <Box sx={{ m: '2rem 0rem' }}>
                        <Typography sx={{
                            fontWeight: '600',
                            fontFamily: 'Roboto',
                            fontSize: '24px',
                            lineHeight: '25px',
                            mb: '0.5rem'
                        }}>Payment Gateway</Typography>
                        <SelectField
                            id="payment_type"
                            name="payment_type"
                            fieldValue={formik.values.payment_type}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fieldTouched={formik.touched.payment_type}
                            fieldError={formik.errors.payment_type}
                        />
                    </Box> */}
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={12}>
                            <Button
                                fullWidth
                                size='large'
                                disableElevation
                                variant='contained'
                                type='button'
                                sx={{
                                    m: '2rem 0rem', textTransform: 'none', fontSize: '20px', lineHeight: '36px', fontFamily: 'poppins', fontWeight: '400', background: '#933118',

                                    '&.MuiButton-root:hover': {
                                        backgroundColor: '#6e1e0a !important'
                                    }
                                }}
                                onClick={handlePayment}
                            >Pay</Button>
                        </Grid>
                    </Grid>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading || rechargeWalletApiLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default Wallet