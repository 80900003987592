import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { AmountTotalStyle, GrandTotalStyle } from '../../Styles/Common/style'
import PropTypes from 'prop-types';

const SubHeadingStyle = {
  fontSize: {
    xs: '14px',
    sm: '14px',
    md: '14px',
    lg: '16px',
    xl: '18px',
  }
}

const TotalAmountSummary = ({ summary }) => {
  const responsiveBreakPoint = useMediaQuery('(max-width: 600px)')
  return (
    <Box sx={{ border: "1px solid #CCCCCC", background: "#F3F5F8 0% 0% no-repeat padding-box", display: responsiveBreakPoint ? "block" : "flex", padding: "1rem" }}>
      <Box sx={{ flex: "1" }}>
        <Typography sx={GrandTotalStyle}>
          Grand Total
        </Typography>
      </Box>
      <Box sx={{
        minWidth: {
          xs: '100%',
          sm: '280px',
          md: '280px',
          lg: '280px',
          xl: '280px',
        }
      }} >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography textAlign={responsiveBreakPoint ? "start" : "end"} width={"50%"} sx={AmountTotalStyle}>
            Amount
          </Typography>
          <Typography sx={AmountTotalStyle} textAlign="end" width={"50%"}>
            {summary?.amount_sum?.toFixed(2)} ₹
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography textAlign={responsiveBreakPoint ? "start" : "end"} width={"50%"} sx={SubHeadingStyle}>
            SGST({summary?.sgst?.percent})
          </Typography>
          <Typography textAlign="end" width={"50%"} sx={SubHeadingStyle}>
            {summary?.sgst?.amount?.toFixed(2)} ₹
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography textAlign={responsiveBreakPoint ? "start" : "end"} width={"50%"} sx={SubHeadingStyle}>
            CGST({summary?.cgst?.percent})
          </Typography>
          <Typography textAlign="end" width={"50%"} sx={SubHeadingStyle}>
            {summary?.cgst?.amount?.toFixed(2)} ₹
          </Typography>
        </Box>
        <hr style={{ width: responsiveBreakPoint ? "100%" : "40%", margin: "auto 0 0 auto" }} />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography textAlign={responsiveBreakPoint ? "start" : "end"} width={"50%"} sx={AmountTotalStyle}>
            Total Amount
          </Typography>
          <Typography sx={AmountTotalStyle} textAlign="end" width={"50%"}>
            {summary?.total_amount_sum?.toFixed(2)} ₹
          </Typography>
        </Box>
      </Box>
      {/* <Box sx={{flex:"1"}}>
      <Typography sx={GrandTotalStyle}>
        Grand Total
      </Typography>
    </Box>
    <Box sx={{minWidth:"280px"}} >
        <Box sx={{ display:"flex",justifyContent:"space-between"}}>
          <Typography textAlign="end" width={"50%"} sx={AmountTotalStyle}>
            Amount
          </Typography>
          <Typography sx={AmountTotalStyle} textAlign="end" width={"50%"}>
            {summary?.amount_sum?.toFixed(2)} ₹
          </Typography>
        </Box>
        <Box sx={{ display:"flex",justifyContent:"space-between"}}>
          <Typography textAlign="end" width={"50%"}>
            SGST({summary?.sgst?.percent})
          </Typography>
          <Typography textAlign="end" width={"50%"} >
            {summary?.sgst?.amount?.toFixed(2)} ₹
          </Typography>
        </Box>
        <Box sx={{ display:"flex",justifyContent:"space-between"}}>
          <Typography textAlign="end" width={"50%"}>
            CGST({summary?.cgst?.percent})
          </Typography>
          <Typography textAlign="end" width={"50%"} >
            {summary?.cgst?.amount?.toFixed(2)} ₹
          </Typography>
        </Box>
        <hr style={{width:"40%", margin:"auto 0 0 auto"}} />
        <Box sx={{ display:"flex",justifyContent:"space-between"}}>
          <Typography textAlign="end" width={"50%"} sx={AmountTotalStyle}>
            Total Amount
          </Typography>
          <Typography sx={AmountTotalStyle} textAlign="end" width={"50%"}>
            {summary?.total_amount_sum?.toFixed(2)} ₹
          </Typography>
        </Box>
    </Box> */}
    </Box>
  )
}

TotalAmountSummary.propTypes = {
  summary: PropTypes.shape(PropTypes.any),
}

export default TotalAmountSummary