import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import HeaderSection from '../../Components/HeaderSection';
import { formContainer, formTitle } from '../../Styles/Import/formSection';
import { useDownloadFileMutation, useLazyGetBillingTransactionQuery } from '../../Services/AccountApi';
import dayjs from 'dayjs';
import { ReactComponent as RefreshSvgIcon } from '../../Assets/icons/refresh-icon.svg';
import BillingTransactionTable from './BillingTransactionTable';
import { LightTooltip, RefreshIconButtonStyle } from '../../Styles/Common/style';
import FilterSelectField from '../../Components/FilterSelectField';
import FilterCustomDateTimePicker from '../../Components/FilterCustomDateTimePicker';
import { Helmet } from 'react-helmet-async';
import GlobalLoader from '../../Components/GlobalLoader';
import DownloadIcon from '../../Assets/icons/downloadBold.svg';
import { enqueueSnackbar } from 'notistack';
import { convertDateintoDDMMYYYY } from '../../Utils/common';

const StatusOptions = [
    { id: "failed", name: "Failed" },
    { id: "pending", name: "Pending" },
    { id: "success", name: "Success" },
]

const ServiceOptions = [
    { id: "import", name: "Import" },
    { id: "export", name: "Export" },
]
const BillingTransactionDetails = () => {
    const [getBillingTransactionApi, { isLoading }] = useLazyGetBillingTransactionQuery()
    const [downloadFileApi, { isLoading: downloadApiLoading }] = useDownloadFileMutation()
    const [tableData, setTableData] = useState()
    const [filterParams, setFilterParams] = useState({ service: "", status: "", page: 1 })
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFilterParams(prev => ({
            ...prev,
            [name]: value
        }))
    }
    const handleDateChange = (value) => {
        setFilterParams(prev => ({
            ...prev,
            date: dayjs(value).format('DD-MM-YYYY')
        }))
    }

    useEffect(() => {
        const handleFilterChange = async () => {
            const { data, error } = await getBillingTransactionApi(filterParams, false)
            if (data) {
                setTableData(data)
            }
            if (error) {
                setTableData([])
            }
        }
        handleFilterChange()
    }, [filterParams, getBillingTransactionApi])

    const handleRefresh = () => {
        setFilterParams({ service: "", status: "", date: "" })
    }

    const handlePageChange = async (page) => {
        setFilterParams(prev => ({ ...prev, page: page }))
    }

    const handleExport = async () => {
        let body = {
            service: filterParams?.service,
            page: filterParams?.page,
            status: filterParams?.status,
            date: filterParams?.date || "",
        }
        const { data, error } = await downloadFileApi(body);
        if (data) {

        }
        if (error) {
            enqueueSnackbar(error?.data?.message || "Something went wrong !", {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });
        }
    }

    return (
        <Box sx={{ background: "#F5F5F5 0% 0% no-repeat padding-box" }}>
            <Helmet>
                <title>Transaction History | Gati se Pragati</title>
            </Helmet>
            <HeaderSection title="TRANSACTION HISTORY" />
            <Box sx={{ ...formContainer, margin: "auto" }} padding={"1rem 0 4rem 0"}>
                <Box display={'flex'} gap={"1rem"} margin={"2rem 0px"}>
                    <Typography flex={'1'} sx={formTitle}> Transaction History </Typography>
                    <LightTooltip title="Download the Transaction History in the Excel Format." arrow>
                        <Box sx={{ ...RefreshIconButtonStyle, background: "#933118 0% 0% no-repeat padding-box", cursor: "pointer" }} onClick={handleExport}>

                            <img src={DownloadIcon} alt="" width={"100%"} />

                            {/* <DownloadIcon sx={{ fill: "#fff", width: "100%", height: "100%" }} /> */}
                        </Box>
                    </LightTooltip>
                    <Box sx={{ ...RefreshIconButtonStyle, cursor: "pointer" }} onClick={handleRefresh}>
                        <RefreshSvgIcon width={"100%"} height={"100%"} rotate={"90deg"} />
                    </Box>
                </Box>
                <Grid container spacing={2} marginBottom={"2rem"} justifyContent="center">
                    <Grid item xs={6} md={6} padding="0px" width="fit-content">
                        <Box width={{ md: "30%", xs: "100%" }}>
                            <FilterSelectField
                                name={'service'}
                                placeholder={'select'}
                                value={filterParams?.service || ""}
                                label={'Select Service'}
                                onChange={handleChange}
                                options={ServiceOptions}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={3} padding="0px" width="fit-content" >
                        <FilterCustomDateTimePicker
                            name={'schedule_date'}
                            placeholder={'select'}
                            value={convertDateintoDDMMYYYY(filterParams?.date)}
                            label={'Filter By Date'}
                            allDates={true}
                            onChange={handleDateChange}
                        // onChange={filterParams?.date}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} padding="0px" width="fit-content" >
                        <FilterSelectField
                            name={'status'}
                            placeholder={'select'}
                            value={filterParams?.status || ""}
                            label={'Search by Status'}
                            onChange={handleChange}
                            options={StatusOptions}
                        />
                    </Grid>
                </Grid>
                {<BillingTransactionTable tableData={tableData} handlePageChange={handlePageChange} />}
            </Box>
            <GlobalLoader open={Boolean(isLoading)} />
        </Box>
    )
}

export default BillingTransactionDetails