import HeroBgImage from "../../Assets/images/bg-banner-large.png";

const heroBgContainer = {
  height: {
    xs: "50dvh",
    sm: "50dvh",
    md: "50dvh",
    lg: "100dvh",
    xl: "100dvh",
  },
  // backgroundImage: `url(${HeroBgImage})`,
  position: "relative",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const heroContentContainer = {
  width: {
    xs: "90%",
    sm: "90%",
    md: "80%",
    lg: "70%",
    xl: "70%",
  },
  height: {
    xs: "50%",
    sm: "40%",
    md: "40%",
    lg: "30%",
    xl: "30%",
  },
  backgroundColor: "rgba(0, 0, 0, 0.68)",
  textAlign: "center",
  padding: {
    xs: "1rem",
    sm: "1.4rem",
    md: "1.5rem",
    lg: "1.8rem",
    xl: "2rem",
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "0.5rem",
};

const heroContentTitle = {
  fontWeight: "bold",
  fontSize: {
    xs: "16px",
    sm: "22px",
    md: "28px",
    lg: "38px",
    xl: "44px",
  },
  lineHeight: {
    xs: "22px",
    sm: "30px",
    md: "40px",
    lg: "47px",
    xl: "54px",
  },
  color: "#FFFFFF",
  fontFamily: "Oswald",
};

const heroContentSubtitle = {
  fontSize: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
  },
  color: "#FFFFFF",
  fontFamily: "Poppins",
  maxWidth: {
    xs: "100%",
    sm: "100%",
    md: "75%",
    lg: "75%",
    xl: "75%",
  },
  margin: "0 auto",
  mt: "0.8rem",
};

const contactButton = {
  backgroundColor: "#933118",
  fontWeight: "bold",
  textTransform: "none",
  fontSize: "20px",
  mb: "1rem",
  mt: "2rem",
};

const orContentBreakWrapper = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "12px",
};

const orContentBreak = {
  width: "80px",
  height: "1px",
  backgroundColor: "#FFFFFF",
};

const orTitle = {
  letterSpacing: "0px",
  color: "#FFFFFF",
  fontFamily: "Poppins",
  fontWeight: "400",
  fontSize: "29px",
  lineHeight: "31px",
};
const appStoreContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "1.5rem",
  mt: "2.5rem",
};

const appStoreWrapper = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.5rem",
  textAlign: "left",
  border: "0.1px solid rgba(255, 255, 255, 0.5)",
  padding: "0.4rem 0.6rem",
  borderRadius: "0.3rem",
};

const appTexts = {
  color: "#FFFFFF",
  fontFamily: "Poppins",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "16px",
};

export {
  heroBgContainer,
  heroContentContainer,
  heroContentTitle,
  heroContentSubtitle,
  contactButton,
  orContentBreakWrapper,
  orContentBreak,
  orTitle,
  appStoreWrapper,
  appTexts,
  appStoreContainer,
};
